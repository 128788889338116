import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    
  }),
  getters: {
    
  },
  mutations: {
    
  },
  actions: {
    async updateOrganizationProfile(store, payload) {
      var res = await axios.post("/organization/updateProfile", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async deleteOrganization(store, payload) {
      var res = await axios.post("/organization/deleteOrganization", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async updateTrackSettings(store, payload) {
      var res = await axios.post("/organization/updateTrackSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
  }
}

export default store;