<template>
      <el-input v-model="term" placeholder="Search..." clearable @clear="clearInput">
      </el-input>
</template>

<script>

export default {
    name: 'SearchInput',
    props: ["initialValue"],
    data(){
        return {
          term:null,
          debouncer:null
        }
    },
    mounted() {
      if(this.initialValue != null)
      {
        this.term = this.initialValue;
      }
    },
    methods: {
      clearInput() {
        this.term = null;
        this.inputChanged();
      },
      inputChanged() {
        this.$emit("changed", this.term);
      }
    },
    watch: {
       'term'() {
        window.clearTimeout(this.debouncer);
        this.debouncer = setTimeout(() => {
          this.inputChanged();
        },350);
      },
    }
}
</script>
