import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    subscriptionHistory:null,
    paymentMethods:null
  }),
  getters: {
    getSubscriptionHistory(state) {
      return state.subscriptionHistory;
    },
    getPaymentMethods(state) {
      return state.paymentMethods;
    },
  },
  mutations: {
    setSubscriptionHistory(state, payload) {
      state.subscriptionHistory = payload;
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload;
    }
  },
  actions: {
    async getSubscriptionHistory(store, payload) {
      var res = await axios.post("/billing/GetSubscriptionHistory", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setSubscriptionHistory", res.data.Data);
      return res.data.Data;
    },
    async getPaymentMethods(store, payload) {
      var res = await axios.post("/billing/getPaymentMethods", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setPaymentMethods", res.data.Data);
      return res.data.Data;
    },
    async addPaymentMethod(store, payload) {
      
      if(payload.CardNumber)
      {
        payload.CardNumber = payload.CardNumber.replaceAll(" ", "");
        payload.CardNumber = payload.CardNumber.replaceAll("-", "");
      }
      
      var res = await axios.post("/billing/addPaymentMethod", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async deletePaymentMethod(store, payload) {
      var res = await axios.post("/billing/deletePaymentMethod", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
    async makePrimaryPaymentMethod(store, payload) {
      var res = await axios.post("/billing/MakePrimaryCard", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      return res.data.Data;
    },
  }
}

export default store;