<template>
    <div class="custom-pagination" v-if="Total > 0">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="Page"
            :page-sizes="[15, 30, 45, 60, 120, 200]"
            :page-size="PageSize"
            layout="sizes, total, prev, pager, next, jumper"
            :total="Total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: ["PageSize","Total","Page"],
    methods: {
      handleSizeChange(val) {
        this.$emit("sizeChanged", val);
      },
      handleCurrentChange(val) {
        this.$emit("pageChanged", val);
      }
    },
    data() {
      return {
        
      };
    }
}
</script>

<style>

</style>