<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-blue p-5 mb-3" v-if="teamUsageLeft == null && formData.Id == null">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>You have unlimited team limit in your account.</span>
          </div>
        </div>
      </div>

       <div class="card bg-blue p-5 mb-3" v-if="teamUsageLeft != null && formData.Id != null">
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>You have {{ teamUsageLeft }} team limit in your account.</span>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="save" v-if="teamUsageLeft == null || teamUsageLeft > 0 || formData.Id == null">
        <el-form-item label="Name" required>
          <el-input v-model="formData.Title" placeholder="ex. Development" required clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Color" required>
          <el-color-picker
            v-model="formData.Color"
            :predefine="predefineColors">
          </el-color-picker>
          <ValidationErrorItem validationKey="Color" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer" v-if="teamUsageLeft == null || teamUsageLeft > 0 || formData.Id == null">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'" icon="fas fa-check">
          {{ formData.Id != null ? 'Save changes' : 'Add team' }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'TeamsAddOrUpdateModal',
    props: ["data"],
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            Id:null,
            Title: null,
            Color:null
          },
          predefineColors: [
            '#8B4DFF',
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585'
          ]
        }   
    },
    mounted() {
      if(this.data)
      {
        this.formData = this.data;
      }

      if(this.formData.Color == null)
      {
        this.formData.Color = '#8b4dff';
      }
    },
    methods: {
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("addUpdateTeam", this.formData);

        if(this.formData.Id != null)
        {
          await this.$store.dispatch("getEmployees");
        }
       
        this.loading = null;
         if(res != null)
        {
          this.$emit('closeDialog');
        }
      }
    },
    computed: {
      teams() {
        return this.$store.getters.getTeams;
      },
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      teamUsageLeft() {
        if(this.organization.Subscription.Teams == null)
        {
          return null;
        }

        return this.organization.Subscription.Teams - this.teams.TotalCount
      }
    }
}
</script>

<style>

</style>