<template>
    <div class="account-wrapper" v-if="account && enums && timer">
        <div class="navbar-wrapper">
            <NavBar />
        </div>
        <div class="account-body">
            <div class="sidebar-wrapper">
                <SideBar />
            </div>
            <vue-page-transition name="fade" class="content-wrapper">
                <router-view></router-view>
            </vue-page-transition>
        </div>
    </div>
    <div v-else>
        <div class="loading-screen">
            <img src="@/assets/img/logo.svg" alt="">
            <p v-html="motto"></p>
        </div>
    </div>
</template>

<script>
import NavBar from './partials/NavBar';
import SideBar from './partials/SideBar';

export default {
    name: 'AccountMain',
    components: {
        NavBar,
        SideBar
    },
    data() {
        return {
            timer:false,
            mottos: [
                "Discipline and focus for excellence in productivity. 💪",
                "Aim for productivity with small steps, achieve big results. 🚀",
                "Make the most of your time, productivity leads to success. ⏱️",
                "Work smarter, faster, and more efficiently - productivity sets you apart. 🌟",
                "Productivity is the invaluable fuel for your journey to success. 🔥"
            ],
            motto: null
        }
    },
    mounted() {
        this.motto = this.mottos[Math.floor(Math.random() * this.mottos.length)]
        setTimeout(() => {
            this.timer = true;
        }, 1000);
    },
    computed: {
      account() {
        return this.$store.getters.getAccount;
      },
      enums() {
        return this.$store.getters.getEnums;
      }
    },
}
</script>

<style lang="less">
    .loading-screen {
        width:100%;
        height: 100vh;
        margin:0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        p {
            margin-top:30px;
        }
        img {
            width:200px;
        }
    }
</style>