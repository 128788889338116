<template>
    <div class="login-page">
        <div class="login-text">
            <h1>Forgot password</h1>
            <p>Dont remeber your password? Reset password here.</p>
        </div>
        <div class="card login-card shadow-md">
            <div class="login-hero">
            </div>

            <div class="login-form">
                <div class="form-header">
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="">
                    <h3>Reset Password</h3>
                    <p>Fill your account email and we will send you a password code.</p>
                </div>
                <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="resetPassword">
                    <el-form-item label="Your e-mail address">
                        <el-input v-model="formData.Email" type="email" placeholder="someone@example.com"  clearable></el-input>
                        <ValidationErrorItem validationKey="Email" />
                    </el-form-item>

                    <el-form-item label="Password recovery code">
                        <el-input v-model="formData.Code" type="text" placeholder="someone@example.com"  clearable></el-input>
                        <ValidationErrorItem validationKey="Code" />
                    </el-form-item>

                     <el-form-item label="New password">
                        <el-input v-model="formData.Password" type="password" placeholder="******" show-password ></el-input>
                        <ValidationErrorItem validationKey="Password" />
                    </el-form-item>

                     <el-form-item label="New password (retype)">
                        <el-input v-model="formData.PasswordRetry" type="password" placeholder="******" show-password ></el-input>
                        <ValidationErrorItem validationKey="PasswordRetry" />
                    </el-form-item>
                    <el-form-item class="login-button">
                        <el-button :loading="loading == 'resetPassword'" type="primary" round @click="resetPassword">Recover my password</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'login' })">Remember your password?</el-button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForgotPasswordIndex',

    data(){
        return {
            loading:null,
            formData: {
                Email:null,
                Code: null,
                Password:null,
                PasswordRetry:null
            }
        }
    },
    mounted() {
        
    },
    methods: {
        async resetPassword() {
            this.loading = 'resetPassword';
            var res = await this.$store.dispatch("resetPassword", this.formData);
            this.loading = null;

            if(res == null)
            {
                return;
            }

            this.$router.push({name:'login'});
        }
    }
}
</script>


