<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter">
      <div class="filter-item purple" :class="showPage == 0 ? 'isactive' : ''" @click="showPage = 0">
        <i class="fas fa-user-cog"></i>
        <span>Profile</span>
      </div>
      <div class="filter-item purple" :class="showPage == 1 ? 'isactive' : ''" @click="showPage = 1">
        <i class="fas fa-lock"></i>
        <span>Security</span>
      </div>
    </div>

    <template v-if="showPage == 0">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Your profile</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">
          <el-form :label-position="'top'" label-width="100px" @keyup.enter.native="updateAccount">
            <el-form-item label="Profile picture" required>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="account.Image" :src="account.Image" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <ValidationErrorItem validationKey="imageUrl" />
            </el-form-item>

            <el-form-item label="Name" required>
              <el-input v-model="account.FirstName" placeholder="John" required clearable></el-input>
              <ValidationErrorItem validationKey="FirstName" />
            </el-form-item>

            <el-form-item label="Surname" required>
              <el-input v-model="account.LastName" placeholder="Doe" required clearable></el-input>
              <ValidationErrorItem validationKey="LastName" />
            </el-form-item>

            <el-form-item label="Email" required>
              <el-input v-model="account.Email" type="email" placeholder="someone@example.com"  clearable></el-input>
              <ValidationErrorItem validationKey="Email" />
            </el-form-item>

            <el-form-item class="space-between-buttons">
              <el-button :loading="loading == 'deleteAccount'" type="danger" icon="fas fa-trash" round @click="deleteAccount">Delete account</el-button>

              <el-button :loading="loading == 'updateAccount'" type="primary" icon="fas fa-check" round @click="updateAccount">Save changes</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    </template>

    <template v-if="showPage == 1">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Change password</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">

          <div class="card bg-blue p-5 mb-3 mt-3">
            <div class="card-alert">
              <i class="fas fa-info-circle"></i>
              <div>
                <span v-if="account.PasswordUpdateDate != null">Your last password change date is <b>{{ moment(account.PasswordUpdateDate).format('DD MMMM yyyy HH:mm') }}</b>, for security reasons we recommend that you renew your account password every 6 months at most.</span>
                <span v-else>
                  You never changed your account password, for security reasons we recommend that you renew your account password every 6 months at most.
                </span>
              </div>
            </div>
          </div>

          <el-form :label-position="'top'" label-width="100px" :model="updatePasswordForm" @keyup.enter.native="updatePassword">

            <el-form-item label="Current password" required>
              <el-input v-model="updatePasswordForm.CurrentPassword" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="CurrentPassword" />
            </el-form-item>

            <el-form-item label="New password" required>
              <el-input v-model="updatePasswordForm.NewPassword" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="NewPassword" />
            </el-form-item>

            <el-form-item label="New password (retype)" required>
              <el-input v-model="updatePasswordForm.NewPasswordRetry" type="password" placeholder="******" show-password required></el-input>
              <ValidationErrorItem validationKey="NewPasswordRetry" />
            </el-form-item>

            <el-form-item class="tracking-buttons">
              <el-button :loading="loading == 'updatePassword'" type="primary" icon="fas fa-check" round @click="updatePassword">Save changes</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    
  </template>

    <el-dialog class="medium" title="Delete account" :visible.sync="deleteAccountModal" center modal-append-to-body destroy-on-close>
      <DeleteAccountModal @closeDialog="deleteOrganizationModal = false" v-if="deleteAccountModal"/>
    </el-dialog>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';
import moment from "moment/min/moment-with-locales";
import DeleteAccountModal from './DeleteAccountModal.vue';

export default {
    name: 'SettingsIndex',
    components: {
      Breadcrumb,
      DeleteAccountModal
    },

    data(){
      return {
        moment,
        meta: {
          title: 'Settings',
          desc:'You can change your profile settings here.'
        },
        loading:null,
        updatePasswordForm: {
          CurrentPassword: null,
          NewPassword: null,
          NewPasswordRetry:null
        },
        showPage: 0,
        deleteAccountModal:false,
      }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.$store.dispatch("getAccount");
    },
    methods: {
      deleteAccount() {
        this.deleteAccountModal = true;
      },
      async updateAccount() {
        this.loading = 'updateAccount';
        await this.$store.dispatch("updateProfile", this.account);
        this.loading = null;
      },
      async updatePassword() {
        this.loading = 'updatePassword';
        await this.$store.dispatch("updatePassword", this.updatePasswordForm);
        this.loading = null;
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      }
    },
    computed: {
      account() {
            return this.$store.getters.getAccount.User;
      },
    }
}
</script>
