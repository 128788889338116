<template>
  <div class="container mt-10" v-if="list" :key="$route.params.id">
    <portal to="action-buttons">
      <el-button type="secondary" size="medium" round icon="fas fa-sync-alt" @click="refresh" :loading="loading == 'refresh'"></el-button>
      <el-button type="primary" size="medium" round icon="fas fa-pen" @click="editListModal = true">Edit</el-button>
    </portal>
    <Breadcrumb :title="meta.title" :desc="meta.desc"></Breadcrumb>

    <div class="activity-filter">
      <div class="filter-item purple" :class="activeTab == 'mail-data' ? 'isactive' : ''" @click="activeTab = 'mail-data'">
        <i :class="loading == 'get-data' ? 'fas fa-spinner fa-spin' : 'fas fa-project-diagram'"></i>
        <span>Mailing data</span>
      </div>
      <div class="filter-item purple" :class="activeTab == 'overview' ? 'isactive' : ''" @click="activeTab = 'overview'">
        <i class="fas fa-chart-line"></i>
        <span>Insights</span>
      </div>
      <div class="filter-item purple" :class="activeTab == 'settings' ? 'isactive' : ''" @click="activeTab = 'settings'">
        <i class="fas fa-exclamation-triangle"></i>
        <span>Settings</span>
      </div>
    </div>

    <div class="card p-5 mb-10" id="activityTable" v-if="activeTab == 'overview'">
      <div class="card-header hr mb-5">
        <div class="card-title">
          <h3>Insights</h3>
        </div>
        <div class="card-actions" v-if="listItems">
          <span class="big-badge blue">Total {{ listItems.TotalCount }}</span>
        </div>
      </div>

      <div class="card-body d-grid overview-grid" v-if="insights">

        <div class="card p-5 no-leader card-badge bg-primary">
          <pre>
            <i class="fas fa-exclamation"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.NotSent }}</h3>
            <p>Not sent</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-primary">
          <pre>
            <i class="fas fa-clock"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Scheduled }}</h3>
            <p>Sheduled</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-primary">
          <pre>
            <i class="fas fa-paper-plane"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Sent }}</h3>
            <p>Sent</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-primary">
          <pre>
            <i class="fas fa-check"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Delivered }}</h3>
            <p>Delivered</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-purple">
          <pre>
            <i class="fas fa-user"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Opened }}</h3>
            <p>Opened</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-green">
          <pre>
            <i class="fas fa-mouse-pointer"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Clicked }}</h3>
            <p>Clicked</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-orange">
          <pre>
            <i class="fas fa-sign-out-alt"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Unsubscribed }}</h3>
            <p>Unsubscribed</p>
          </div>
        </div>

        <div class="card p-5 no-leader card-badge bg-red">
          <pre>
            <i class="fas fa-times"></i>
          </pre>
          <div class="d-column">
            <h3>{{ insights.Failed }}</h3>
            <p>Failed</p>
          </div>
        </div>

      </div>
    </div>


    <div class="card p-5 mb-10" id="activityTable" v-if="activeTab == 'mail-data' && listItems">
      <div class="card-header hr">
        <div class="card-title">
          <h3 class="d-flex">
            <div>
              <SearchInput @changed="searchTermChanged" />
            </div>

            <div class="ml-5">
              <StatusFilterSelectbox @changed="(statuses) => {filter.Statuses = statuses}" />
            </div>
          </h3>
          <span v-if="multipleSelection.length > 0">({{multipleSelection.length}} item selected) click <a href="javascript:;" @click="sendSelected">here</a> to start sending.</span>
        </div>
        <div class="card-actions">
          <el-tooltip class="item" effect="dark" content="Export current list to .xlsx" placement="top">
            <el-button type="secondary" size="medium" round icon="fas fa-download" :loading="loading == 'export-list'" @click="exportList"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Import data from .xlsx" placement="top">
            <el-button type="secondary" @click="importList" :loading="loading == 'import-list'" size="medium" round icon="fas fa-upload"></el-button>
          </el-tooltip>


          
          <el-button type="secondary" size="medium" round icon="fas fa-paper-plane" :disabled="listItems.TotalCount == 0" @click="sendAll">Start sending</el-button>
          <el-button type="primary" size="medium" round icon="fas fa-plus" @click="addUpdateItemModal = true">Add</el-button>
        </div>
      </div>

      <div class="activity-table" id="tableTop">
          <input type="file" @change="uploadFile" style="display: none" ref="file" />

        <NoData v-if="listItems.TotalCount == 0" />
        <el-table class="contact-table"  :data="listItems.Items" v-if="listItems.Items.length > 0" v-loading="loading == 'get-data'" @selection-change="handleSelectionChange">

          <el-table-column
            type="selection"
            width="55">
          </el-table-column>

          <el-table-column label="Person">
            <template slot-scope="scope">
              <div class="contact-item">
                <span>{{ scope.row.FirstName }} <b>{{ scope.row.LastName }}</b></span>
                <span>{{ scope.row.Title }} <b v-if="scope.row.Company">@ {{ scope.row.Company }}</b></span>
              </div>
              
            </template>
          </el-table-column>

          <el-table-column label="Email">
            <template slot-scope="scope">
              <span class="link"><a :href="'mailto:' + scope.row.Email"><i class="fas fa-envelope"></i> {{ scope.row.Email }}</a></span>
            </template>
          </el-table-column>

          <el-table-column label="Phone">
            <template slot-scope="scope">
              <span class="link" v-if="scope.row.PhoneNumber"><a :href="'tel:' + scope.row.PhoneNumber"><i class="fas fa-phone-square-alt"></i> {{ scope.row.PhoneNumber }}</a></span>
            </template>
          </el-table-column>

          <el-table-column label="Status">
            <template slot-scope="scope">

              <el-popover
                placement="top"
                width="440"
                trigger="hover">
                <el-table :data="scope.row.History" style="max-height:300px;overflow:auto;">
                  <el-table-column width="120" property="Status" label="Status">
                    <template slot-scope="scope">
                      <span class="badge" slot="reference" :class="$getStatusBgColor(scope.row.Status)">{{ $getEnumTitle('MailStatus', scope.row.Status) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="140" property="CreatedAt" label="Created at">
                    <template slot-scope="scope">
                      {{moment(scope.row.CreatedAt).format("DD.MM.yyyy HH:mm")}}
                    </template>
                  </el-table-column>
                  <el-table-column width="140" property="StatusMessage" label="Status message"></el-table-column>
                </el-table>
                 <span class="badge" slot="reference" :class="$getStatusBgColor(scope.row.Status)">{{ $getEnumTitle('MailStatus', scope.row.Status) }} <span v-if="scope.row.History.length > 0">(+{{scope.row.History.length}})</span></span>
              </el-popover>

            
            </template>
          </el-table-column>
          

          <el-table-column width="160">
            <template slot-scope="scope" >
              <el-row :key="scope.row.ID + scope.row.Status">

                
                <el-tooltip class="item" effect="dark" content="Send email" placement="top">
                  <el-button type="info" icon="fas fa-paper-plane" :disabled="scope.row.status == 6" size="small" circle @click="sendSingleMail(scope.row)" :loading="loading == 'send-single-mail-' + scope.row.ID"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                  <el-button type="success" icon="fas fa-pen" size="small" circle @click="updateItem(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                  <el-button type="danger" icon="fas fa-trash" size="small" circle @click="deleteItem(scope.row.ID)" :loading="loading == 'delete-' + scope.row.ID"></el-button>
                </el-tooltip>

              </el-row>
            </template>
          </el-table-column>

        </el-table>
      </div>

      <Pagination 
        :Page="filter.Page" 
        :PageSize="filter.PageSize" 
        :Total="listItems.TotalCount"  
        @sizeChanged="(args) => { filter.PageSize = args }"
        @pageChanged="(args) => { filter.Page = args }"
        />

    </div>

    <div class="card p-5 mb-10" id="activityTable" v-if="activeTab == 'settings'">
      <div class="card-header hr">
        <div class="card-title">
          <h3>Settings</h3>
        </div>
      </div>
      <div class="card-body">

          <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
              <el-form-item label="From name" required>
                <el-input v-model="settings.FromName" placeholder="..." required clearable></el-input>
                <ValidationErrorItem validationKey="FromName" />
              </el-form-item>
            </el-form>

            <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
              <el-form-item label="From e-mail address" required>
                <el-input v-model="settings.FromEmailAddress" placeholder="..." required clearable></el-input>
                <ValidationErrorItem validationKey="FromEmailAddress" />
              </el-form-item>
            </el-form>

            <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
              <el-form-item label="Reply e-mail address" required>
                <el-input v-model="settings.ReplyEmailAddress" placeholder="..." required clearable></el-input>
                <ValidationErrorItem validationKey="ReplyEmailAddress" />
              </el-form-item>
            </el-form>

            

          <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
            <el-form-item label="Subject" required>
              <el-input v-model="settings.Subject" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="Subject" />
            </el-form-item>
          </el-form>

          <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
            <el-form-item label="Body" required>

                <quill-editor ref="editor" :options="editorOption" v-model="settings.Body" @ready="onEditorReady($event)" />

              <!-- <el-input v-model="settings.Body" type="textarea" placeholder="..." :autosize="{ minRows: 10, maxRows: 20}" required clearable></el-input> -->
              <ValidationErrorItem validationKey="Body" />
            </el-form-item>
          </el-form>

          <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
              <el-form-item label="CC">
                <el-select
                  v-model="settings.Cc"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="You can add multiple CC's by pressing Enter...">
                  <el-option
                    v-for="item in cc"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="Cc" />
              </el-form-item>
            </el-form>

            <el-form :label-position="'top'" label-width="100%" style="width:100%;" :model="settings">
              <el-form-item label="BCC">
                <el-select
                  v-model="settings.Bcc"
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  placeholder="You can add multiple BCC's by pressing Enter...">
                  <el-option
                    v-for="item in bcc"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="Bcc" />
              </el-form-item>
            </el-form>


      <div class="d-flex justify-content-between w-100">
         <el-button type="danger" icon="fas fa-exclamation-circle" @click="removeList" :loading="loading == 'remove-list'">Remove entire list</el-button>
        <el-button type="primary" icon="fas fa-check" @click="saveSettings" :loading="loading == 'save-settings'">Save</el-button>
      </div>

          
        </div>
    </div>

    <el-dialog class="medium" title="Edit list" :visible.sync="editListModal" center modal-append-to-body destroy-on-close>
      <EditListModal @closeDialog="editListModal = false" @listChanged="getList"  v-if="editListModal"/>
    </el-dialog>

    <el-dialog class="medium" title="Add/update data" :visible.sync="addUpdateItemModal" center modal-append-to-body destroy-on-close>
      <AddUpdateListItemModal :data="addUpdateData" @closeDialog="addUpdateItemModal = false" @getData="getData"  v-if="addUpdateItemModal"/>
    </el-dialog>

  </div> 
</template>

<script>

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import axios from "axios"
import Breadcrumb from '../partials/Breadcrumb';
import Pagination from '@/components/Pagination';
import SearchInput from '@/components/form/SearchInput.vue';
import StatusFilterSelectbox from '@/components/form/StatusFilterSelectbox.vue';
import moment from "moment/min/moment-with-locales";
import EditListModal from './EditListModal.vue';
import AddUpdateListItemModal from './AddUpdateListItemModal.vue';

import { quillEditor, Quill } from "vue-quill-editor";
import QuillResize from "quill-resize-module";
Quill.register("modules/resize", QuillResize);

var BackgroundClass = Quill.import('attributors/class/background');
var ColorClass = Quill.import('attributors/class/color');
var SizeStyle = Quill.import('attributors/style/size');

SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px']

Quill.register(BackgroundClass, true);
Quill.register(ColorClass, true);
Quill.register(SizeStyle, true);

export default {
    name: 'ListIndex',
    components: {
      Breadcrumb,
      Pagination,
      SearchInput,
      StatusFilterSelectbox,
      AddUpdateListItemModal,
      EditListModal,
      quillEditor
    },
    data(){
        return {
          moment,
          editListModal: false,
          quillUploading:false,
          addUpdateItemModal:false,
          loading:'init',
          activeTab:'mail-data',
          multipleSelection: [],
          editorOption: {
            theme: 'snow',
            modules: {
              toolbar: {
                container: [
                  ["bold", "italic", "underline", "strike"], 
                  [{ indent: "-1" }, { indent: "+1" }], 
                  [{ color: [] }, { background: [] }], 
                  ["clean"], 
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'size': [false, '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
                  ["link", "image", "video"]
                  ],
                handlers: {
                  image: () => {
                    this.selectLocalImage();
                  }
                }
              },

              syntax: {
                highlight: text => hljs.highlightAuto(text).value
              },

              resize: {
                modules: ["Resize"]
              }
            }
          },
          meta: {
            title: '...',
            desc: '...'
          },
          filter: {
            Page:1,
            PageSize:24,
            ProjectListId:null,
            SearchTerm:null,
            Statuses:[]
          },
          cc:["ozkan@internative.net","kemal@internative.net","begum@internative.net","heskikoy@internative.net","ebakiy@internative.net","ykankaya@internative.net","sales@internative.net","support@internative.net","support@holacon.com"],
          bcc:["ozkan@internative.net","kemal@internative.net","begum@internative.net","heskikoy@internative.net","ebakiy@internative.net","ykankaya@internative.net","sales@internative.net","support@internative.net","support@holacon.com"],
          addUpdateData:null,
        }
    },
    async mounted() {
      this.filter.ProjectListId = this.$route.params.id;

      this.$setMeta(this.meta);
      await Promise.all([
        this.getList(),
        this.getData(),
        this.getInsights()
      ]);

    },
    methods: {
      async refresh() {
        this.loading = 'refresh';
        await Promise.all([
          this.getList(),
          this.getData(),
          this.getInsights()
        ]);
        this.$message.info("Data refreshed successfully.");
        this.loading = null;
      },
      importList() {
        this.$refs.file.click();
      },
      async uploadFile() {
        this.loading = 'import-list';
        let file = this.$refs.file.files[0];
        var data = new FormData();
        data.append("file", file);
        
        var res = await axios.post("/projectListItem/import?Id=" + this.filter.ProjectListId, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        if(res != null && !res.data.HasError)
        {
          await this.getData();
        }

        this.$refs.file.value = null;
        this.loading = null;
      },
      async exportList() {
        this.loading = 'export-list';
        var res = await this.$store.dispatch("projectListItemExport", {
            Page:1,
            PageSize:10000,
            ProjectListId:this.filter.ProjectListId,
            SearchTerm:this.filter.SearchTerm,
            Statuses:this.filter.Statuses
        });
        if(res != null)
        {
          window.open(res, "_blank");
        }
        this.loading = null;
      },
      searchTermChanged(term) {
        this.filter.SearchTerm = term;
      },
      onEditorReady(editor) {
        editor.getModule("toolbar").addHandler("image", () => {
          this.selectLocalImage(editor);
        });
      },
      selectLocalImage(editor) {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.click();
        input.onchange = () => {
          const file = input.files[0];
          if (/^image\//.test(file.type)) {
            this.saveToServer(file, editor);
            this.quillUploading = "Image upload process started.";
          } else {
            //console.warn("You could only upload images.");
            this.quillUploading = "You could only upload images.";
            setTimeout(() => {
              this.quillUploading = false;
            }, 3000);
          }
        };
      },
      insertToEditor(url, editor) {
        editor.insertEmbed(editor.getSelection().index, "image", url);
      },
      saveToServer(file, editor) {
        const fd = new FormData();

        fd.append("image", file);
        const xhr = new XMLHttpRequest();
        xhr.open("POST", axios.defaults.baseURL + "account/uploadFile?uploadType=html", true);
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));

        xhr.onload = () => {
          if (xhr.status === 200) {
            const body = JSON.parse(xhr.responseText);
            if (body.HasError == false) {
              const url = body.Data.Url;
              this.insertToEditor(url, editor);
              this.quillUploading = "Image upload process completed successfully.";
              setTimeout(() => {
                this.quillUploading = false;
              }, 3000);
            } else {
              this.$message.error(body.Message);
              this.quillUploading = body.Message;
              setTimeout(() => {
                this.quillUploading = false;
              }, 3000);
            }
          } else {
            this.quillUploading = xhr.statusText + " (Code: " + xhr.status + ")";
            setTimeout(() => {
              this.quillUploading = false;
            }, 3000);
          }
        };

        xhr.send(fd);
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      async sendAll() {
        this.$confirm('You are about to send an email to entire list. Continue?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info'
          }).then(async () => {
            
            this.loading = 'send-selected';
            var res = await this.$store.dispatch("projectListItemStartSending", {
              ProjectListId: this.filter.ProjectListId,
              Ids: [],
              EntireList:true,
              ScheduleDate:null
            });
            if(res != null)
            {
              await this.getData();
            }

            this.loading=null;
          }).catch(() => {
                   
          });
      },
      async sendSelected() {
        this.$confirm('You are about to send an email to selected ' + this.multipleSelection.length + ' records. Continue?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info'
          }).then(async () => {
            
            this.loading = 'send-selected';
            var res = await this.$store.dispatch("projectListItemStartSending", {
              ProjectListId: this.filter.ProjectListId,
              Ids: this.multipleSelection.map(x => (x.ID)),
              EntireList:false,
              ScheduleDate:null
            });

            if(res != null)
            {
              await this.getData();
            }

            this.loading=null;
          }).catch(() => {
                   
          });
      },
      async sendSingleMail(item) {
        this.$confirm('You are about to send an email to ' + item.Email + '. Continue?', 'Confirmation', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'info'
          }).then(async () => {
            
            this.loading = 'send-single-mail-' + item.ID;

            var res = await this.$store.dispatch("projectListItemStartSending", {
              ProjectListId: item.ProjectListId,
              Ids: [item.ID],
              EntireList:false,
              ScheduleDate:null
            });

            if(res != null)
            {
              await this.getData();
            }

            this.loading=null;
          }).catch(() => {
                   
          });
      },
      async getList() {
        await this.$store.dispatch("projectListGet", {Id: this.filter.ProjectListId});
        this.meta.title = this.list.Item.Title;
        this.meta.desc = this.list.Item.Description ?? "No description";

        this.$setMeta(this.meta);
      },
      updateItem(item) {
        this.addUpdateData = item;
        this.addUpdateItemModal = true;
      },
      //removeList
      async removeList() {
         this.$confirm('This will permanently delete the data. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(async () => {
            
            this.loading = 'remove-list';
            await this.$store.dispatch("projectListDelete", {Id: this.filter.ProjectListId});
            await this.$store.dispatch("projectList");
            this.$router.push({name: 'overview'}).catch(() => {});
            this.loading=null;

          }).catch(() => {
                   
          });
      
      },
      async deleteItem(id) {

         this.$confirm('This will permanently delete the data. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(async () => {
            
            this.loading = 'delete-' + id
            await this.$store.dispatch("projectListItemDelete", {Id: id});
            await this.getData();
            this.loading=null;

          }).catch(() => {
                   
          });
      
      },
      //
      async saveSettings() {
        this.loading = 'save-settings';
        this.settings.Id = this.list.Item.ID;
        if(this.settings.Cc == null)
        {
          this.settings.Cc = [];
        }

        if(this.settings.Bcc == null)
        {
          this.settings.Bcc = [];
        }

        await this.$store.dispatch("projectListUpdateSettings", this.settings);

        this.loading = null;
      },
      async getData() {
        this.loading = 'get-data';
        await this.$store.dispatch("projectListItemsList", this.filter);
        this.loading = null;
      },
      async getInsights() {
        this.loading = 'getInsights';
        await this.$store.dispatch("projectListInsights", this.filter);
        this.loading = null;
      }
    },
    computed: {
      list() {
        return this.$store.getters.getProjectList;
      },
      settings() {
        return this.list.Item.Settings;
      },
      listItems() {
        return this.$store.getters.getProjectListItems;
      },
       insights() {
        return this.$store.getters.getProjectListInsights;
      },
    },
    watch: {
      '$route.params.id': async function() {
        this.filter.ProjectListId = this.$route.params.id;
        this.filter.SearchTerm = null;
        this.filter.Statuses = [];
        this.filter.Page = 1;

        await Promise.all([
          this.getList(),
          this.getData(),
          this.getInsights()
        ]);
      },
      'addUpdateItemModal':function() {
        if(this.addUpdateItemModal == false)
        {
          this.addUpdateData = null;
        }
      },
      'filter.Page'() {
        this.getData();
        this.$toTop("tableTop");
      },
      'filter.PageSize'() {
        this.filter.Page = 1;
        this.getData();
        this.$toTop("tableTop");
      },
      'filter.SearchTerm'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
           this.$toTop("tableTop");
        }
      },
      'filter.Statuses'() {
        if(this.filter.Page != 1)
        {
          this.filter.Page = 1;
        }
        else
        {
          this.getData();
        }
      }
    }
}
</script>

<style>
  .quill-editor .ql-container .ql-editor {
    padding-top: 70px;
}

.quill-editor .ql-toolbar {
    display: block;
    top: 0px;
    bottom: unset;
    background: white;

}

.quill-editor .ql-container .ql-editor {
    padding-top: 70px;
    max-height: 400px;
    overflow: auto;
}

.ql-picker-item[data-value='10px']::before, .ql-picker-label[data-value='10px']::before {
  content: '10px' !important;
}

.ql-picker-item[data-value='12px']::before, .ql-picker-label[data-value='12px']::before {
  content: '12px' !important;
}

.ql-picker-item[data-value='14px']::before, .ql-picker-label[data-value='14px']::before {
  content: '14px' !important;
}

.ql-picker-item[data-value='16px']::before, .ql-picker-label[data-value='16px']::before {
  content: '16px' !important;
}

.ql-picker-item[data-value='18px']::before, .ql-picker-label[data-value='18px']::before {
  content: '18px' !important;
}

.ql-picker-item[data-value='20px']::before, .ql-picker-label[data-value='20px']::before {
  content: '20px' !important;
}

.ql-picker-item[data-value='24px']::before, .ql-picker-label[data-value='24px']::before {
  content: '24px' !important;
}

.ql-picker-item[data-value='30px']::before, .ql-picker-label[data-value='30px']::before {
  content: '30px' !important;
}

.ql-picker-item[data-value='32px']::before, .ql-picker-label[data-value='32px']::before {
  content: '32px' !important;
}

.ql-picker-item[data-value='36px']::before, .ql-picker-label[data-value='36px']::before {
  content: '36px' !important;
}
</style>
