<template>
    <div class="breadcrumb">
        <div class="b-haeder">
            <h3 v-if="title != null && title != ''">{{ title }}</h3>
            <p v-if="desc != null && desc != ''">{{ desc }}</p>
        </div>

        <portal-target name="action-buttons">
            
        </portal-target>
    </div>
</template>

<script>
import PortalVue from "portal-vue";
export default {
    name: 'breadcrumb',
    components: {
        PortalVue
    },
    props: {
        title: String,
        desc: String
    },
}
</script>
