import axios from "axios";

const store = {
  state: () => ({
    projectLists:null,
    projectList:null,
    insights:null
  }),
  getters: {
    getProjectLists(state) {
      return state.projectLists;
    },
    getProjectList(state) {
      return state.projectList;
    },
    getProjectListInsights(state) {
      return state.insights;
    }
  },
  mutations: {
    setProjectLists(state, payload) {
      state.projectLists = payload;
    },
    setProjectList(state, payload) {
      state.projectList = payload;
    },
    setProjectListInsights(state, payload) {
      state.insights = payload;
    }
  },
  actions: {
    async projectListList(store, payload) {
      var res = await axios.post("/projectList/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProjectLists", res.data.Data);
      return res.data.Data;
    },
    async projectListGet(store, payload) {
      var res = await axios.post("/projectList/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProjectList", res.data.Data);
      return res.data.Data;
    },
    async projectListInsights(store, payload) {
      var res = await axios.post("/projectList/Insights", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProjectListInsights", res.data.Data);
      return res.data.Data;
    },
    async projectListAddOrUpdate(store, payload) {
      var res = await axios.post("/projectList/addOrUpdate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListDelete(store, payload) {
      var res = await axios.post("/projectList/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListCopy(store, payload) {
      var res = await axios.post("/projectList/copy", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListUpdateSettings(store, payload) {
      var res = await axios.post("/projectList/UpdateSettings", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;