
<template>
  <div id="card-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-blue p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <span>We will charge you <b>$1</b> and it will be cancelled automatically</span>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData">
        <el-form-item label="Card holder" required>
          <el-input v-model="formData.CardHolder" placeholder="John Doe" required clearable></el-input>
          <ValidationErrorItem validationKey="CardHolder" />
        </el-form-item>

        <el-form-item label="Card number" required>
          <el-input v-model="formData.CardNumber" v-mask="'#### #### #### ####'" placeholder="0000 0000 0000 0000" required clearable></el-input>
          <ValidationErrorItem validationKey="CardNumber" />
        </el-form-item>

        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Exp. month" required>
              <el-select v-model="formData.ExpireMonth" clearable placeholder="Select...">
                <el-option v-for="item in 12" :key="item" :value="item.toString()">
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <ValidationErrorItem validationKey="ExpireMonth" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Exp. year" required style="padding:0px 5px">
              <el-select v-model="formData.ExpireYear" clearable placeholder="Select...">
                <el-option v-for="item in years" :key="item" :value="item.toString()">
                  <span style="float: left">{{ item }}</span>
                </el-option>
              </el-select>
              <ValidationErrorItem validationKey="ExpireYear" />
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="Cvv" required>
              <el-input v-model="formData.Cvc" placeholder="..." maxlength="4" required clearable></el-input>
              <ValidationErrorItem validationKey="Cvc" />
            </el-form-item>
          </el-col>
        </el-col>

      </el-form>

      <div class="payment-providers mt-3">
        <img src="@/assets/img/logo_band_colored.svg" alt="" />
      </div>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'">Add</el-button>
      </div>
    </div>

    <div id="htmlContent" v-html="htmlContent">

    </div>
  </div>
</template>

<script>
export default {
    name: 'CardAddOrUpdateModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          htmlContent:null,
          formData: {
            CardHolder: '',
            CardNumber: '',
            ExpireMonth: '',
            ExpireYear: '',
            Cvc: '',
          }
        }   
    },
    methods: {
      async save() {
        this.loading = 'save';

        var res = await this.$store.dispatch("addPaymentMethod", this.formData);
        if(res != null)
        {
          this.htmlContent = res.HtmlContent;

          this.$nextTick(function () {
            document.getElementById("iyzico-3ds-form").submit();
          });
        }

        this.loading = null;
      }
    },
    computed: {
      years() {
        var result = [];
        for (let i = 2023; i <= 2100; i++) {
          result.push(i);
        }

        return result;
      }
    }
}
</script>

<style>

</style>