<template>
    <div class="login-page">
        <div class="login-text">
            <h1>Login</h1>
            <p>Are you already Worktivity customer? Login to your account.</p>
        </div>
        <div class="card login-card shadow-md">
            <div class="login-hero">
            </div>

            <div class="login-form">
                <div class="form-header">
                    <img src="@/assets/img/logo.svg" alt="">
                    <h3>Welcome Back</h3>
                    <p>Fill your email and password to sign in.</p>
                </div>
                <el-form :label-position="'top'" label-width="100px" :model="formData"  @keyup.enter.native="signIn">
                    <el-form-item label="Your e-mail address">
                        <el-input v-model="formData.email" type="email" placeholder="someone@example.com"  clearable></el-input>
                        <ValidationErrorItem validationKey="Email" />
                    </el-form-item>
                    <el-form-item label="Your password">
                        <el-input v-model="formData.password" type="password" placeholder="******" show-password ></el-input>
                        <ValidationErrorItem validationKey="Password" />
                    </el-form-item>
                    <el-form-item class="login-button">
                        <el-button :loading="loading == 'signin'" type="primary" round @click="signIn">Login</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'forgotpassword' })">Forgot Password?</el-button>
                </div>
                <div class="seperator">
                    <span>or</span>
                </div>
                <div class="signup-button">
                    <span> Don't have an account? <el-button type="text" @click="$router.push({ name: 'register' })">Sign up</el-button> </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoginIndex',

    data(){
        return {
            loading:null,
            formData: {
                email: null,
                password: null
            }
        }
    },

    mounted() {
        
    },
    methods: {
        async signIn() {
            this.loading = 'signin';
            var res = await this.$store.dispatch("signIn",this.formData);
            this.loading = null;

            if(res == null)
            {
                return;
            }
            
            await this.$store.dispatch("getAccount");
            this.$router.push({name: 'overview'});
            
        }
    }
}
</script>
