import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
//import "element-ui/lib/theme-chalk/index.css";
import "./assets/element/theme/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./utils/prototype.js";
import "./utils/axios.js";
import VueTheMask from "vue-the-mask";
import VueMask from "v-mask";
import PortalVue from "portal-vue";
import moment from "moment/min/moment-with-locales";
import {Message} from 'element-ui';

moment.locale("en");

import VuePageTransition from 'vue-page-transition';

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


import ValidationErrorItem from '@/views/partials/ValidationErrorItem';
import NoData from '@/components/NoData';

Vue.config.productionTip = false;

Vue.use(ElementUI, { locale });
Vue.use(VuePageTransition);
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueTheMask);
Vue.use(VueMask);
Vue.use(PortalVue);

Vue.prototype.$getEnumTitle = function(category, key) {
  return this.$store.getters.getEnums.find(x => x.Title == category).Enums.find(x => x.Key == key).DisplayName;
};

Vue.prototype.$getEnums = function(key) {
  return this.$store.getters.getEnums.find(x => x.Title == key).Enums;
};

Vue.prototype.$copyToClipboard = function(text) {
        var textArea = document.createElement("textarea");

        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
            Message.success("Copying to clipboard is successfully");
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }


    Vue.prototype.$underDevelopment = function(text) {
            Message.info("This feature is under development.");
      
    }



Vue.prototype.$getRoleColor = function(role) {
  switch (role) {
                case 0: //User
                  return "red";
                case 1: //Admin
                    return "green";
                case 2: //Owner
                    return "green";
                case 3: //Coowner
                    return "orange";
                case 4: //Manager
                    return "blue";
                case 5: //Employee
                    return "yellow";
                default:
                    return "red";
            }
};

Vue.prototype.$getStatusBgColor = function(role) {

  switch (role) {
                case 0: //NotSent
                  return "primary";
                case 1: //Scheduled
                    return "blue";
                case 2: //Sent
                    return "blue";
                case 3: //Delivered
                    return "blue";
                case 4: //Opened
                    return "green";
                case 5: //Clicked
                    return "green";
                case 6: //Unsubscribed
                    return "red";
                case 7: //Failed
                    return "red";
                default:
                    return "red";
            }
};

Vue.prototype.$toTop = function(target) {
  if(!target)
  {
    document.getElementById("app").scrollTo({top: 0, behavior: 'smooth'});
  }

    document.getElementById(target).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
};

Vue.prototype.$sum = function(items, prop){
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
};

Vue.prototype.$percentage = function(a, b) {
  if(a == 0 || b == 0)
    return 0;

  return Math.round(((a * 100) / b));
};



Vue.prototype.$parseTime = function(minutes, short=false) {
    var mins_num = parseFloat(minutes, 10); // don't forget the second param
    var hours   = Math.floor(mins_num / 60);
    var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
    var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));

    // Appends 0 when unit is less than 10
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    if(short)
    {
      return hours+'h '+minutes+'m';
    }
    else
    {
      return hours+' hrs. '+minutes+' mins.';
    }
};


Vue.prototype.$setMeta = (meta) => {
  document.title = meta.title + " | Internative Marketing";
  if(document.getElementsByTagName('meta')["description"] != null)
  {
    document.getElementsByTagName('meta')["description"].content = meta.desc;
  }
}

Vue.component("ValidationErrorItem", ValidationErrorItem);
Vue.component("NoData", NoData);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
