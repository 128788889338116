<template>
    <div class="sidebar-menu">

        <h6>Projects <span class="badge" @click="createProject"><i :class="loading == 'create-project' ? 'fas fa-spinner fa-spin' : 'fas fa-plus'"></i></span></h6>

        <template v-if="projectList"> 
          <div class="sidebar-item" v-for="item in projectList.Items" :key="item.ID">
            <div class="sidebar-title">
                <div class="sidebar-inf" @click="addActiveList(item.ID)">
                    <el-avatar shape="square" :size="26" :src="'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
                    <span>{{ item.Title }}</span>
                </div>
                <div class="actions">
                    <span class="badge">{{ item.Lists.length }}</span> 

                    <el-tooltip class="item" effect="dark" content="Delete project" placement="top">
                        <span class="edit" @click="deleteProject(item)"><i class="fas fa-trash"></i></span>
                    </el-tooltip>

                    <el-tooltip class="item" effect="dark" content="Update project" placement="top">
                        <span class="edit" @click="updateProject(item)"><i class="fas fa-pen"></i></span>
                    </el-tooltip>

                    <el-tooltip class="item" effect="dark" content="Update mail provider" placement="top">
                        <span class="edit" @click="updateProjectSettings(item)"><i class="fas fa-cogs"></i></span>
                    </el-tooltip>
                    
                    
                    
                </div>
            </div>
            
             <div class="sidebar-inner-list" :class="activeList.find(x => x == item.ID) ? '' : 'hidden-list'">
                <router-link :to="{name:'list', params:{id: inner.ID}}" v-for="inner in item.Lists" :key="inner.ID">
                    {{ inner.Title }}
                </router-link>
            
                <div class="add-new-list" @click="createList(item.ID)">
                    <i :class="loading == 'create-list-' + item.ID ? 'fas fa-spinner fa-spin' : 'fas fa-plus'"></i><span>Add new list</span>
                </div>
            </div>

          </div>
        </template>
    
        <el-dialog class="medium" title="Edit project" :visible.sync="editProjectModal" center modal-append-to-body destroy-on-close>
            <EditProjectModal :data="editProject" @closeDialog="editProjectModal = false"  v-if="editProjectModal"/>
        </el-dialog>

        <el-dialog class="medium" title="Edit project settings" :visible.sync="editProjectSettingsModal" center modal-append-to-body destroy-on-close>
            <EditProjectSettingsModal :data="editProjectSettings" @closeDialog="editProjectSettingsModal = false"  v-if="editProjectSettingsModal"/>
        </el-dialog>

    </div>
</template>


<script>
import EditProjectModal from './EditProjectModal.vue';
import EditProjectSettingsModal from './EditProjectSettingsModal.vue';
export default {
    name: 'SideBar',
    components: {
        EditProjectModal,
        EditProjectSettingsModal
    },
    data(){
        return {
            loading:null,
            editProjectModal: false,
            editProjectSettingsModal: false,
            activeList:[],
            editProject:null,
            editProjectSettings:null
        }
    },
    async mounted() {
        await this.getData();
    },
    methods: {
        updateProject(item) {
            this.editProject = item;
            this.editProjectModal = true
        },
        async deleteProject(item) {

         this.$confirm('This will permanently delete the data. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(async () => {
            
            this.loading = 'delete-' + item.ID
            await this.$store.dispatch("projectDelete", {Id: item.ID});
            await this.getData();
            this.$router.push({name: 'overview'}).catch(() => {});
            this.loading=null;

          }).catch(() => {
                   
          });
      
      },
        async updateProjectSettings(item) {

            var res = await this.$store.dispatch("projectGet", {Id: item.ID})
            if(res != null)
            {
                this.editProjectSettings = res.Item;
                this.editProjectSettingsModal = true
            }
            
        },
        addActiveList(item) {
            if (this.activeList.find(x => x == item))
            {
                this.activeList.splice(this.activeList.find(x => x == item), 1);
            } else {
                this.activeList.push(item);
            }
        },
        async getData()
        {
            this.loading = 'get-data';
            await this.$store.dispatch("projectList");
            this.loading = null;
        },
        async createProject() {
            this.loading = 'create-project';

            await this.$store.dispatch("projectAddOrUpdate", {
                Id:null,
                Title:"New project",
            });
            await this.getData();
            this.loading = null;
        },
        async createList(id) {
            this.loading = 'create-list-' + id;

            await this.$store.dispatch("projectListAddOrUpdate", {
                Id: null,
                ProjectId: id,
                Title: "New list",
            });
            await this.getData();
            this.loading = null;
        },
    },
    computed: {
        getEnums() {
            return this.$store.getters.getEnums;
        },
        projectList() {
        return this.$store.getters.getProjects;
      },
    }
}
</script>

