
<template>
  <div id="employees-add-or-update-dialog" class="custom-modal">
    <div class="modal-body">

      <div class="card bg-red p-5 mb-3" >
        <div class="card-alert">
          <i class="fas fa-info-circle"></i>
          <div>
            <p>You are about to delete entire organization.</p>
            <p>All of your data will be permanently deleted.</p>
            <p>This action can not be undo, are you sure about this?</p>
          </div>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData" @submit.native.prevent="false" @keyup.enter.native="deleteOrganization">
        <el-form-item label="Your password" required>
          <el-input v-model="formData.Password" type="password" placeholder="******" required clearable></el-input>
          <ValidationErrorItem validationKey="Password" />
        </el-form-item>

        <el-form-item label="Reason" required>
          <el-input v-model="formData.DeletionReason" type="text" placeholder="Please specify..." required clearable></el-input>
          <ValidationErrorItem validationKey="DeletionReason" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="danger" icon="fas fa-exclamation-circle" @click="deleteOrganization" :loading="loading == 'delete'">Delete organization</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DeleteOrganizationModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          formData: {
            DeletionReason:null,
            Password:null,
          }
        }   
    },
    methods: {
      async deleteOrganization() {
        this.loading = 'delete';

        var res = await this.$store.dispatch("deleteOrganization", this.formData);
        
        this.loading = null;
        if(res != null)
        {
          await this.$store.dispatch("signOut");
          await this.$router.push({name: "login"});
          //this.$emit('closeDialog');
        }
      }
    }
}
</script>

