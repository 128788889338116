import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main'
import LoginIndex from '../views/login/LoginIndex'
import UnsubscribeIndex from '../views/login/UnsubscribeIndex'
import RegisterIndex from '../views/login/RegisterIndex'
import ForgotPasswordIndex from '../views/login/ForgotPasswordIndex'
import ResetPasswordIndex from '../views/login/ResetPasswordIndex'

import AccountMain from '../views/AccountMain'
import OverviewIndex from '../views/dashboard/OverviewIndex'
import ListIndex from '../views/dashboard/ListIndex'


import CreateOrganizationIndex from '../views/organization/CreateOrganizationIndex'
import EmployeesIndex from '../views/organization/EmployeesIndex'
import OrganizationSettingsIndex from '../views/organization/OrganizationSettingsIndex'

import SettingsIndex from '../views/account/SettingsIndex'
import BillingIndex from '../views/account/BillingIndex'
import StartSubscriptionIndex from '../views/account/StartSubscriptionIndex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'login',
        component: LoginIndex,
      },
      {
        path: 'unsubscribe',
        name: 'unsubscribe',
        component: UnsubscribeIndex,
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterIndex,
      },
      {
        path: 'forgot-password',
        name: 'forgotpassword',
        component: ForgotPasswordIndex,
      },
      {
        path: 'reset-password',
        name: 'resetpassword',
        component: ResetPasswordIndex,
      },
      {
        path: 'app',
        name: 'account',
        component: AccountMain,
        meta: {
            authGuard: true
        },
        children: [
          {
            path: 'dashboard/overview',
            name: 'overview',
            component: OverviewIndex
          },
          {
            path: 'dashboard/list/:id',
            name: 'list',
            component: ListIndex,
            meta: {
              watchParam: 'id' //
            }
          }
        ]
      }
    ],
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    document.getElementById("app").scrollTo({top: 0, behavior: 'smooth'});
    const loggedInUserDetail = !!localStorage.getItem("token");
    if (to.matched.some(m => m.meta.authGuard) && !loggedInUserDetail)
        next({ name: "login" });
    else if (['login','register','forgotpassword','resetpassword'].some(x => x == to.name) && loggedInUserDetail)
      next({ name: "overview" });
    else next();
});


export default router


