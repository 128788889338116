
<template>
  <div id="edit-project-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false" :model="formData" @keyup.enter.native="save">
        <el-form-item label="List name" required>
          <el-input v-model="formData.Title" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Description">
          <el-input v-model="formData.Description" type="textarea" placeholder="..." :autosize="{ minRows: 3, maxRows: 5}" clearable></el-input>
          <ValidationErrorItem validationKey="Description" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" icon="fas fa-check" @click="save" :loading="loading == 'save'">Save</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'EditListModal',
    components: {
        
    },
    data(){
        return { 
          loading:null,
          htmlContent:null,
          formData: {
            Id:null,
            ProjectId:null,
            Title: null,
            Description: null,
          }
        }   
    },
    mounted() {
      this.formData.Id = this.currentListId;
      this.formData.ProjectId = this.currentList.ProjectId;
      this.formData.Title = this.currentList.Title;
      this.formData.Description = this.currentList.Description;

    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("projectListAddOrUpdate", this.formData);
        if(res != null)
        {
          await this.$store.dispatch("projectList");
          this.$emit('listChanged'); 
          this.$emit('closeDialog'); 
        }
        this.loading = null;
      },
    },
    computed: {
      currentListId() {
        return this.$route.params.id;
      },
      projects() {
        return this.$store.getters.getProjects;
      },
      currentList() {
        return this.projects.Items.find(x => x.Lists.some(y => y.ID == this.currentListId)).Lists.find(x => x.ID == this.currentListId);
      },
      lists() {
        return this.$store.getters.getProjectLists;
      }
    }
}
</script>

<style>

</style>