
<template>
  <div id="edit-project-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @keyup.enter.native="save">
      

        <el-form-item label="Provider" required>
          <el-select v-model="formData.Provider" filterable placeholder="Select...">
            <el-option
              v-for="item in $getEnums('MailProvider')"
              :key="item.Key"
              :label="item.Value"
              :value="item.Key">
            </el-option>
          </el-select>
          <ValidationErrorItem validationKey="Provider" />
        </el-form-item>

        <el-form-item label="Base Url" required>
          <el-input v-model="formData.BaseUrl" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="BaseUrl" />
        </el-form-item>

        <el-form-item label="Authenticator Type" required>
          <el-input v-model="formData.AuthenticatorType" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="AuthenticatorType" />
        </el-form-item>

        <el-form-item label="Client secret" required>
          <el-input v-model="formData.ClientSecret" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="ClientSecret" />
        </el-form-item>

        <el-form-item label="Domain" required>
          <el-input v-model="formData.Domain" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="Domain" />
        </el-form-item>

        <el-form-item label="Resource" required>
          <el-input v-model="formData.Resource" placeholder="..." required clearable></el-input>
          <ValidationErrorItem validationKey="Resource" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'">Save</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'EditProjectSettingsModal',
    components: {
        
    },
    props:["data"],
    data(){
        return { 
          loading:null,
          formData: {
            ProjectId:null,
            Provider:0,
            BaseUrl:null,
            AuthenticatorType:null,
            ClientSecret:null,
            Domain:null,
            Resource:null,
          }
        }   
    },
    mounted() {
      this.formData.ProjectId = this.data.ID;
      if(this.data.MailProvider)
      {
        this.formData.Provider = this.data.MailProvider.Provider;
        this.formData.BaseUrl = this.data.MailProvider.BaseUrl;
        this.formData.AuthenticatorType = this.data.MailProvider.AuthenticatorType;
        this.formData.ClientSecret = this.data.MailProvider.ClientSecret;
        this.formData.Domain = this.data.MailProvider.Domain;
        this.formData.Resource = this.data.MailProvider.Resource;
      }
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("projectUpdateMailProvider",this.formData);
        if(res != null)
        {
          await this.$store.dispatch("projectList");
          this.$emit('closeDialog');
        }
        
        this.loading = null;
      }
    },
    computed: {

    }
}
</script>

<style>

</style>