<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc">
      <portal to="action-buttons">
        <el-button type="primary" round icon="fas fa-ticket-alt" @click="true" :loading="false" :disabled="false">Redeem code</el-button>
      </portal>
    </Breadcrumb>

    <div class="card p-7 mb-10 bg-blue">
      <div class="card-header">
        <div class="card-title">
          <h3>Your free trial will be expire at 05.07.2023 18:47</h3>
        </div>
      </div>
      <ul class="unordered-list mb-0 mt-3">
        <li>If you have any questions, please feel free to <a href="/contact-us">contact us</a>.</li>
        <li>Requests sent during weekends or on holidays will be replied on business days.</li>
      </ul>
    </div>

    <div class="card p-5 mb-10" id="start-subscription-page">

      <div class="card-header hr mb-5">
        <div class="card-title">
          <h3>Choose your subscription plan.</h3>
        </div>
        <div class="card-actions">
          <el-button type="primary" size="medium" @click="true" round>See details</el-button>
        </div>
      </div>

      <el-form :label-position="'top'" label-width="100px" :model="formData">
        <div class="sub-item-conteiner mb-5">
          <div class="sub-item" :class="item.id == formData.selectedPackage ? 'selected' : ''" v-for="item in packages" :key="item.id" @click="formData.selectedPackage = item.id">
            <div class="sub-item-title">
              <h5>
                {{ item.title }}
              </h5>
              <span>
                ${{ item.price }} per user / month
              </span>
            </div>
            <div class="sub-item-select">
              <el-button type="success" :plain="item.id != formData.selectedPackage" round @click="formData.selectedPackage = item.id">{{ item.id == formData.selectedPackage ? 'Selected' : 'Select' }} </el-button>
            </div>
          </div>
        </div>

        <el-form-item v-if="formData.selectedPackage != null" label="How many employees including you do you have?" required>
          <el-input-number v-model="formData.teamMemberCount" :min="1" :max="9999" required></el-input-number>
          <ValidationErrorItem validationKey="organisation" />
        </el-form-item>

        <div class="card p-5 bg-purple no-leader mt-10 mb-5" v-if="formData.selectedPackage != null">

          <el-form-item label="Card holder" required>
            <el-input v-model="formData.cardHolder" placeholder="John Doe" required clearable></el-input>
            <ValidationErrorItem validationKey="cardHolder" />
          </el-form-item>

          <el-form-item label="Card number" required>
            <el-input v-model="formData.cardNo" v-mask="'#### #### #### ####'" placeholder="0000 0000 0000 0000" required clearable></el-input>
            <ValidationErrorItem validationKey="cardNo" />
          </el-form-item>

          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="Exp. month" required>
                <el-select v-model="formData.expMonth" clearable placeholder="Select...">
                  <el-option v-for="item in 12" :key="item" :value="item">
                    <span style="float: left">{{ item }}</span>
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="expMonth" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Exp. year" required>
                <el-select v-model="formData.expYear" clearable placeholder="Select...">
                  <el-option v-for="item in 6" :key="item" :value="item">
                    <span style="float: left">{{ item }}</span>
                  </el-option>
                </el-select>
                <ValidationErrorItem validationKey="expYear" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Cvv" required>
                <el-input v-model="formData.CVV" placeholder="..." maxlength="4" required clearable></el-input>
                <ValidationErrorItem validationKey="CVV" />
              </el-form-item>
            </el-col>
          </el-row>

          <div class="payment-type">
            <p>Pay yearly for 2 months discount!</p>
            <el-switch v-model="formData.paymentType" active-text="Pay by year" inactive-text="Pay by month"> </el-switch>
          </div>

          <div class="summary">
            <div class="sum-icon">
              <i class="fas fa-shopping-basket"></i>
            </div>
            <div class="sum-desc">
              <p><b>Plan:</b> {{ packages.find(x => x.id == formData.selectedPackage).title }}</p>
              <p><b>Team member:</b> {{ formData.teamMemberCount }}</p>
              <p v-if="formData.paymentType"><b>Price:</b> <pre>${{ packages.find(x => x.id == formData.selectedPackage).price * formData.teamMemberCount * 12 }}</pre> ${{ packages.find(x => x.id == formData.selectedPackage).price * formData.teamMemberCount * 10 }} / yearly</p>
              <p v-else><b>Price:</b> ${{ packages.find(x => x.id == formData.selectedPackage).price * formData.teamMemberCount}} / monthly</p>
            </div>
          </div>

        </div>

        <div class="aggreements-area">
          <el-checkbox v-model="formData.aggreements"></el-checkbox>
          <span>I've ready and accept worktivity <a target="_blank" href="#">terms of use</a> and <a target="_blank" href="#">distance sales</a> policies.</span>
        </div>

        <el-form-item class="tracking-buttons" v-if="formData.selectedPackage != null">
          <el-button :loading="false" :disabled="formData.aggreements == false" type="primary" icon="fas fa-check" round @click="true">Start subscription</el-button>
        </el-form-item>

        
      
      </el-form>

      <div class="payment-providers mt-3">
        <img src="@/assets/img/logo_band_colored.svg" alt="" />
      </div>

    </div>

  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'StartSubscriptionIndex',
    components: {
      Breadcrumb
    },

    data(){
      return {
        meta: {
          title: 'Subscription',
          desc:'You can start or change your subscription here.'
        },
        formData: {
          selectedPackage: null,
          teamMemberCount: 1,
          cardHolder: '',
          cardNo: '',
          expMonth: '',
          expYear: '',
          CVV: '',
          paymentType: false,
          aggreements: false
        },
        packages: [
          {
            id: '1',
            title: 'Starter',
            price: '2.99',
          },
          {
            id: '2',
            title: 'Growth',
            price: '4.99',
          },
          {
            id: '3',
            title: 'Pro',
            price: '6.99',
          }
        ],
        
      }
    },
    mounted() {
      this.$setMeta(this.meta);
    }
}
</script>
