<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />
  </div> 
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';

import moment from "moment/min/moment-with-locales";

export default {
    name: 'OverviewIndex',
    components: {
      Breadcrumb
    },

    data(){
        return {
          moment,
          meta: {
            title: 'Overview',
            desc: 'You can reach your todays overview reports here.'
          },
        }
    },
    mounted() {
      this.$setMeta(this.meta);
    },
    computed: {
      
    }
}
</script>

