import axios from "axios"
import Vue from "vue";
import {Message} from 'element-ui';
import store from "@/store";
import router from "@/router";


axios.defaults.baseURL = 'https://marketing-api.internative.net/';
//axios.defaults.baseURL = 'http://localhost:5020/';
//axios.defaults.baseURL = 'http://10.249.3.106:5020/';

Vue.prototype.$axios = axios;

if(localStorage.getItem("token") != null)
{
  var currentToken = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = currentToken;
}

axios.interceptors.response.use(
  function(response) {
    store.commit('setValidationErrors', response.data.ValidationErrors);

    if(response.data.Message != null)
    {
      if(response.data.HasError)
      {
        Message.error(response.data.Message);
      }
      else
      {
        Message.success(response.data.Message);
      }
    }
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      store.dispatch("signOut", null);
      router.push("/").catch(()=>{});
      return null;
    }
    else if (500 === error.response.status) {
      Message.error("Something went wrong.");
      return null;
    }
    else if (502 === error.response.status) {
      Message.info("We are currently under maintanence, please try again a few moments later.");
      router.push("/under-maintenance").catch(()=>{});
      //TODO: Özkan - 502 sayfası yapılmalı.
      return null;
    }
    else if (403 === error.response.status || 404 === error.response.status) {
      Message.error("Not found");
      error.response = {
        ...error.response,
        Data: {
          HasError: true,
          Message: "The request you made could not be found. Please refresh the screen and try again."
        }
      };
      return error.response;
    } 
    else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(function (config) {
  config.url = config.url.toLowerCase()
  store.commit('setValidationErrors', []);
  return config;
});


