<template>
    <div style="display: flex;justify-content: center; align-items: center; flex-direction: column; height: 100vh;">
        <template v-if="loading == 'unsubscribe'">
            <h3>Loading...</h3>
            <i class="fa fa-spinner fa-spin"></i>
        </template>
        <template v-else>
            <h3>We are sorry</h3>
            <br>
            <p>You are unsubscribed from list successfully.</p>
            <p>You wont receive any email from us.</p>
        </template>
    </div>
</template>

<script>

export default {
    name: 'UnsubscribeIndex',

    data(){
        return {
            loading:null,
            formData: {
                Id: null,
            }
        }
    },
    mounted() {
        this.formData.Id = this.$route.query.Id;
        this.unsubscribe();
    },
    methods: {
        async unsubscribe() {
            this.loading = 'unsubscribe';
            var res = await this.$store.dispatch("unsubscribe",this.formData);
            this.loading = null;

            if(res == null)
            {
                return;
            }

            this.loading = null;
        }
    }
}
</script>
