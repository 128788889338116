import axios from "axios";
import { Message } from "element-ui";

const store = {
  state: () => ({
    account:null,
  }),
  getters: {
    getAccount(state) {
      return state.account;
    },
  },
  mutations: {
    setAccount(state, payload) {
      state.account = payload;
    }
  },
  actions: {
    async getAccount(store, payload) {
      var res = await axios.post("/account/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setAccount", res.data.Data);
      return res.data.Data;
    },
    async updateProfile(store, payload) {
      var res = await axios.post("/account/updateProfile", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      await store.dispatch("getAccount");
      return res.data.Data;
    },
    async updatePassword(store, payload) {
      var res = await axios.post("/account/updatePassword", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      await store.dispatch("getAccount");
      return res.data.Data;
    },
    async deleteAccount(store, payload) {
      var res = await axios.post("/account/deleteAccount", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;