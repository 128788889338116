<template>
    <div class="login-page">
        <div class="login-text">
            <h1>Sign up</h1>
            <p>You dont have a account? Signup now, its free.</p>
        </div>
        <div class="card login-card shadow-md">
            <div class="login-hero">
            </div>

            <div class="login-form">
                <div class="form-header">
                    <img src="@/assets/img/worktivity-horizontal-logo-default.svg" alt="">
                    <h3>Create an account</h3>
                    <p>Your 7 days trial will be started automatically. No credit card required.</p>
                </div>
                <el-form :label-position="'top'" label-width="100px" :model="formData">
                    <el-form-item label="Name">
                        <el-input v-model="formData.name" type="text" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="Surname">
                        <el-input v-model="formData.lastname" type="text" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model="formData.email" type="email" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="Password">
                        <el-input v-model="formData.password" type="password" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="Password (Retype)">
                        <el-input v-model="formData.passwordretype" type="password" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="form-aggreements">
                            <el-checkbox v-model="formData.aggreements"></el-checkbox> I've ready and accept worktivity terms of use and privacy policies.
                        </div>
                    </el-form-item>
                    <el-form-item class="login-button">
                        <el-button type="primary" round>Create account</el-button>
                    </el-form-item>
                </el-form>

                <div class="forgot-button">
                    <el-button type="text" @click="$router.push({ name: 'forgotpassword' })">Forgot Password?</el-button>
                </div>
                <div class="seperator">
                    <span>or</span>
                </div>
                <div class="signup-button">
                    <span> Are you already have an account? <el-button type="text" @click="$router.push({ name: 'login' })">Sign in</el-button> </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterIndex',

    data(){
        return {
            formData: {
                name: '',
                lastname: '',
                email: '',
                password: '',
                passwordretype: '',
                aggreements: false,
            }
        }
    },

    mounted() {
              
    },
}
</script>
