
<template>
  <div id="edit-project-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" @submit.native.prevent="false" :model="formData" @keyup.enter.native="save">
        <el-form-item label="Name" required>
          <el-input v-model="formData.FirstName" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="FirstName" />
        </el-form-item>

        <el-form-item label="Surname">
          <el-input v-model="formData.LastName" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="LastName" />
        </el-form-item>

        <el-form-item label="Email" required>
          <el-input v-model="formData.Email" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Email" />
        </el-form-item>

        <el-form-item label="Phone">
          <el-input v-model="formData.PhoneNumber" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="PhoneNumber" />
        </el-form-item>

        <el-form-item label="Company">
          <el-input v-model="formData.Company" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Company" />
        </el-form-item>

        <el-form-item label="Title">
          <el-input v-model="formData.Title" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Website">
          <el-input v-model="formData.Website" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Website" />
        </el-form-item>

        <el-form-item label="Notes">
          <el-input v-model="formData.Notes" type="textarea" placeholder="..." :autosize="{ minRows: 3, maxRows: 5}" clearable></el-input>
          <ValidationErrorItem validationKey="Notes" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" icon="fas fa-check" @click="save" :loading="loading == 'save'">Save</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'AddUpdateListItemModal',
    components: {
        
    },
    props:["data"],
    data(){
        return { 
          loading:null,
          htmlContent:null,
          formData: {
            Id:null,
            ProjectListId:null,
            FirstName:null,
            LastName:null,
            PhoneNumber:null,
            Email:null,
            Company:null,
            Title:null,
            Website:null,
            Notes:null
          }
        }   
    },
    mounted() {
      if(this.data)
      {
        this.formData.Id = this.data.ID;
        this.formData.FirstName = this.data.FirstName;
        this.formData.LastName = this.data.LastName;
        this.formData.Email = this.data.Email;
        this.formData.PhoneNumber = this.data.PhoneNumber;
        this.formData.Company = this.data.Company;
        this.formData.Title = this.data.Title;
        this.formData.Website = this.data.Website;
        this.formData.Notes = this.data.Notes;
        this.formData.ProjectListId = this.data.ProjectListId;
      }
      else
      {
        this.formData.ProjectListId = this.currentListId;
      }
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("projectListItemAddOrUpdate", this.formData);
        if(res != null)
        {
          this.$emit('getData'); 
          this.$emit('closeDialog'); 
        }
        this.loading = null;
      },
    },
    computed: {
      currentListId() {
        return this.$route.params.id;
      },
      projects() {
        return this.$store.getters.getProjects;
      },
      currentList() {
        return this.projects.Items.find(x => x.Lists.some(y => y.ID == this.currentListId)).Lists.find(x => x.ID == this.currentListId);
      },
      lists() {
        return this.$store.getters.getProjectLists;
      }
    }
}
</script>

<style>

</style>