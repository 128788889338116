<template>
    <transition name="el-fade-in" mode="out-in">
    <span class="text-danger" v-if="getValidationItem != null">{{ getValidationItem.Value }}</span>
    </transition>
</template>

<script>
export default {
    name: 'validationErrorItem',
    components: {

    },
    props:["validationKey"],

    data(){
        return {

        }
    },
    computed: {
        getValidationItem() {
            return this.$store.getters.getValidationItems.find(x => x.Key == this.validationKey);
        }
    }
}
</script>
