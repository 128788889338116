
<template>
  <div id="edit-project-dialog" class="custom-modal">
    <div class="modal-body">

      <el-form :label-position="'top'" label-width="100px" :model="formData" @keyup.enter.native="save">
        <el-form-item label="Project image">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="formData.Image" :src="formData.Image" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <ValidationErrorItem validationKey="projectImage" />
        </el-form-item>

        <el-form-item label="Project name" required>
          <el-input v-model="formData.Title" placeholder="..." clearable></el-input>
          <ValidationErrorItem validationKey="Title" />
        </el-form-item>

        <el-form-item label="Project description">
          <el-input v-model="formData.Description" type="textarea" placeholder="..." :autosize="{ minRows: 3, maxRows: 5}" clearable></el-input>
          <ValidationErrorItem validationKey="Description" />
        </el-form-item>

      </el-form>

    </div>
    <div class="modal-footer" slot="footer">
      <div class="left-buttons">
        <el-button @click="$emit('closeDialog')">Cancel</el-button>
      </div>
      <div class="right-buttons">
        <el-button type="primary" @click="save" :loading="loading == 'save'">Save</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
    name: 'EditProjectModal',
    components: {
        
    },
    props:["data"],
    data(){
        return { 
          loading:null,
          formData: {
            Id:null,
            image: '',
            Title: null,
            Description: null,
          }
        }   
    },
    mounted() {
      this.formData.Id = this.data.ID;
      this.formData.Title = this.data.Title;
      this.formData.Description = this.data.Description;
    },
    methods: {
      async save() {
        this.loading = 'save';
        var res = await this.$store.dispatch("projectAddOrUpdate",this.formData);
        if(res != null)
        {
          await this.$store.dispatch("projectList");
          this.$emit('closeDialog');
        }
        this.loading = null;
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      }
    },
    computed: {

    }
}
</script>

<style>

</style>