import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

import authModule from './auth.js';
import accountModule from './account.js';
import organizationModule from './organisation.js';
import billingModule from './billing.js';


import projectModule from './project.js';
import projectListModule from './projectList.js';
import projectListItemModule from './projectListItem.js';


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authModule,
    accountModule,
    organizationModule,
    billingModule,
    projectModule,
    projectListModule,
    projectListItemModule,
  },
  state: {
    validationErrors:[],
    timezones:[],
    enums:null,
  },
  getters: {
    getValidationItems(state) {
      return state.validationErrors;
    },
    getEnums(state) {
      return state.enums;
    },
    getTimezones(state) {
      return state.timezones;
    }
  },
  mutations: {
    setValidationErrors(state, payload) {
      state.validationErrors = payload;
    },
    setEnums(state, payload) {
      state.enums = payload;
    },
    setTimezones(state, payload) {
      state.timezones = payload;
    }
  },
  actions: {
    async getEnums(store, payload) {
      var res = await axios.get("/definition/listEnums");
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setEnums", res.data.Data.Items);
      return res.data.Data;
    },
    async getTimezones(store, payload) {
      var res = await axios.get("/definition/listTimezones");
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setTimezones", res.data.Data.Items);
      return res.data.Data;
    }
  },
})
