<template>
      <el-select v-model="id" collapse-tags multiple filterable placeholder="All statuses" @change="selectionChanged" @clear="clearSelection">
        <el-option
          v-for="item in $getEnums('MailStatus')"
          :key="item.Key"
          :label="$getEnumTitle('MailStatus', item.Key)"
          :value="item.Key">
        </el-option>
      </el-select>
</template>

<script>

export default {
    name: 'StatusFilterSelectbox',
    props: ["selectedId"],
    data(){
        return {
          id:[],
        }
    },
    mounted() {
      if(this.selectedId)
      {
        this.id = this.selectedId;
      }
    },
    methods: {
      clearSelection() {
        this.id = [];
        this.selectionChanged();
      },
      selectionChanged() {
        this.$emit("changed", this.id);
      },
    }
}
</script>
