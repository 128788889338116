import axios from "axios";

const store = {
  state: () => ({
    projects:null,
  }),
  getters: {
    getProjects(state) {
      return state.projects;
    }
  },
  mutations: {
    setProjects(state, payload) {
      state.projects = payload;
    }
  },
  actions: {
    async projectList(store, payload) {
      if(!payload)
      {
        payload = {
          Page:1,
          PageSize:1000,
          IncludeProjectLists:true
        };
      }
      var res = await axios.post("/project/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProjects", res.data.Data);
      return res.data.Data;
    },
    async projectGet(store, payload) {
      var res = await axios.post("/project/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectAddOrUpdate(store, payload) {
      var res = await axios.post("/project/addOrUpdate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectDelete(store, payload) {
      var res = await axios.post("/project/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectUpdateMailProvider(store, payload) {
      var res = await axios.post("/project/updateMailProvider", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;