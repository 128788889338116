<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter" v-if="teams && employees">
      <div class="filter-item purple" :class="activeTab == 'employees' ? 'isactive' : ''" @click="activeTab = 'employees'">
        <i class="fas fa-user-friends"></i>
        <span>Employees<b>{{ employees.TotalCount }}</b></span>
      </div>
      <div class="filter-item purple" :class="activeTab == 'teams' ? 'isactive' : ''" @click="activeTab = 'teams'">
        <i class="fas fa-layer-group"></i>
        <span>Teams<b>{{ teams.TotalCount }}</b></span>
      </div>
    </div>

    <template v-if="employees && teams">
      <template v-if="activeTab == 'employees'">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{ employees.TotalCount }} employee(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-user-friends" @click="addEmployee" round>Add new employee</el-button>
          </div>
        </div>

        <div class="activity-table">
          <el-table :data="employees.Items">

            <el-table-column label="Employee">
              <template slot-scope="scope">
                <div class="app-title" v-if="scope.row.User != null">
                  <el-avatar v-if="scope.row.User.Image != null" :size="25" :src="scope.row.User.Image"></el-avatar>
                  <span>
                    {{ scope.row.User.FirstName }} <pre>{{ scope.row.User.LastName }}</pre>
                    <b>{{ scope.row.User.Email }}</b>
                  </span>
                </div>
                <div class="app-title" v-else>
                  <el-avatar :size="25" src="https://img.imageus.dev/https://worktivity.s3.eu-central-1.amazonaws.com/default/user.jpg?width=160&height=160&mode=cover"></el-avatar>
                  <span>
                    {{ scope.row.Invitation.FirstName }} <pre>{{ scope.row.Invitation.LastName }}</pre>
                    <b>{{ scope.row.Invitation.Email }}</b>
<el-tooltip class="item" effect="dark" :content="'Click to copy, link will be expire at ' + moment(scope.row.Invitation.ExpireDate).format('DD.MM.yyyy HH:mm')" placement="top" v-if="new Date(scope.row.Invitation.ExpireDate) < new Date()">
                    <span class="text-green" @click="$copyToClipboard('https://app.useworktivity.com/register?invitationCode=' + scope.row.Invitation.InvitationCode)"><i class="fas fa-copy"></i> Invited</span>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" content="Invitation is expired, you can click to resend it." placement="top" v-else>
                    <el-button type="text" size="small" @click="resendInvitation(scope.row.ID)" :loading="loading == 'resend-invitation-' + scope.row.ID"><span class="text-red"><i class="fas fa-envelope"></i> Expired, click to resend</span></el-button>
                  </el-tooltip>
                  </span>


                   
                </div>
              </template>
            </el-table-column>

            <el-table-column label="Team" width="250">
              <template slot-scope="scope">
                <span class="badge"><i class="fas fa-circle" :style="'color: ' + scope.row.Team.Color"></i> {{ scope.row.Team.Title }}</span>
              </template>
            </el-table-column>

            <el-table-column label="Role" width="150">
              <template slot-scope="scope">
                <span class="badge" :class="$getRoleColor(scope.row.Role)">{{ $getEnumTitle('TypeOfAuthority', scope.row.Role) }}</span>
              </template>
            </el-table-column>

            <!-- <el-table-column label="Status" width="70">
              <template slot-scope="scope">
                <el-switch v-model="scope.row.isActive"></el-switch>
              </template>
            </el-table-column> -->

            <el-table-column label="App version" width="150">
              <template slot-scope="scope">
                <span class="badge" v-if="scope.row.User != null && scope.row.User.AppVersion  != null">{{ scope.row.User.AppVersion }}</span>
                <span class="badge" v-else>N/A</span>
              </template>
            </el-table-column>

            <el-table-column width="150">
              <template slot-scope="scope">
                <el-row>

                  <el-tooltip class="item" effect="dark" content="Edit track settings" placement="top">
                    <el-button :type="scope.row.EnableCustomTrackSettings ? 'success' : 'warning'" icon="fas fa-user-secret" size="small" circle @click="updateTrackSettings(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Edit employee" placement="top" v-if="scope.row.Role != 2">
                    <el-button type="info" icon="fas fa-pen" size="small" circle @click="updateEmployee(scope.row)"></el-button>
                  </el-tooltip>


                  <el-tooltip class="item" effect="dark" content="Delete employee" placement="top" v-if="scope.row.Role != 2">
                    <el-button type="danger" icon="fas fa-trash" size="small" circle @click="deleteEmployee(scope.row)"></el-button>
                  </el-tooltip>


                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>

    <template v-if="activeTab == 'teams'">
      <div class="card p-5 mb-10">
        <div class="card-header">
          <div class="card-title">
            <h3>You have {{teams.TotalCount}} team(s) here.</h3>
          </div>
          <div class="card-actions">
            <el-button type="primary" icon="fas fa-layer-group" @click="addteam" round>Add new team</el-button>
          </div>
        </div>

        <div class="activity-table">
          <el-table :data="teams.Items" :row-class-name="tableRowClassName">

            <el-table-column label="Team">
              <template slot-scope="scope">
                <span><i class="fas fa-circle" :style="'color: ' + scope.row.Color"></i> <b>{{ scope.row.Title }}</b></span>
              </template>
            </el-table-column>

            <el-table-column label="Employees" width="100">
              <template slot-scope="scope">
                <span class="badge">{{ scope.row.EmployeeCount }}</span>
              </template>
            </el-table-column>

            <el-table-column width="100">
              <template slot-scope="scope">
                <el-row>

                  <el-tooltip class="item" effect="dark" content="Edit team" placement="top">
                    <el-button type="info" icon="fas fa-pen" size="small" circle @click="updateTeam(scope.row)"></el-button>
                  </el-tooltip>

                  <el-tooltip class="item" effect="dark" content="Delete team" placement="top">
                    <el-button type="danger" icon="fas fa-trash" size="small" circle @click="deleteTeam(scope.row)"></el-button>
                  </el-tooltip>

                </el-row>
              </template>
            </el-table-column>

          </el-table>
        </div>

      </div>
    </template>
    </template>

    <el-dialog class="medium" :title="employeeModalTitle" :visible.sync="employeesAddOrUpdateModal" center modal-append-to-body destroy-on-close>
      <EmployeesAddOrUpdateModal @closeDialog="employeesAddOrUpdateModal = false" :data="addUpdateEmployee" v-if="employeesAddOrUpdateModal"/>
    </el-dialog>

    <el-dialog class="medium" :title="teamsAddOrUpdateModalTitle" :visible.sync="teamsAddOrUpdateModal" center modal-append-to-body destroy-on-close>
      <TeamsAddOrUpdateModal @closeDialog="teamsAddOrUpdateModal = false" :data="addUpdateTeam" v-if="teamsAddOrUpdateModal"/>
    </el-dialog>

     <el-dialog class="medium" :title="employeeDeleteModalTitle" :visible.sync="deleteEmployeeModal" center modal-append-to-body destroy-on-close>
      <DeleteEmployeeModal @closeDialog="deleteEmployeeModal = false" :employeeId="deleteEmployeeId" v-if="deleteEmployeeModal"/>
    </el-dialog>

    <el-dialog class="medium" :title="deleteTeamModalTitle" :visible.sync="deleteTeamModal" center modal-append-to-body destroy-on-close>
      <DeleteTeamModal @closeDialog="deleteTeamModal = false" :teamId="deleteTeamId" v-if="deleteTeamModal"/>
    </el-dialog>



  </div>
</template>

<script>


//TODO: Employee isminin altında İnvitation status a göre tekrar gönder vs butonları çıkmalı


import Breadcrumb from '../partials/Breadcrumb';
import EmployeesAddOrUpdateModal from './EmployeesAddOrUpdateModal.vue';
import DeleteEmployeeModal from './DeleteEmployeeModal.vue';
import DeleteTeamModal from './DeleteTeamModal.vue';
import TeamsAddOrUpdateModal from './TeamsAddOrUpdateModal.vue';
import moment from "moment/min/moment-with-locales";


export default {
    name: 'EmployeesIndex',
    components: {
      Breadcrumb,
      EmployeesAddOrUpdateModal,
      TeamsAddOrUpdateModal,
      DeleteEmployeeModal,
      DeleteTeamModal
    },

    data(){
        return {
          loading:null,
          moment,
          meta: {
            title: 'Employees',
            desc:'You can manage your teams and employees here.'
          },
          employeesAddOrUpdateModal: false,
          teamsAddOrUpdateModal: false,
          teamsAddOrUpdateModalTitle: 'Add new team',
          addUpdateTeam: {
            Id:null,
            Title:null,
            Color:null,
          },
          activeTab: 'employees',
          employeeModalTitle: 'Add new employee',
          addUpdateEmployee: {
            ID:null,
            FirstName:null,
            LastName:null,
            Email:null,
            TeamId:null,
            Role:null,
          },
          deleteEmployeeModal:false,
          deleteEmployeeId:null,
          employeeDeleteModalTitle:'Delete employee',
          deleteTeamId:null,
          deleteTeamModalTitle:null,
          deleteTeamModal:false,
          editTrackSettingsModalTitle: '',
          editTrackSettingsModal:false,
          editTrackSettings: {
            EmployeeId: null,
            EnableCustomTrackSettings: false,
            EnableScreenshots: false,
            BlurScreenshots: false,
            EnableTimelapseVideos: false,
            ScreenCaptureIntervalMins: 5
          }
        }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.$store.dispatch("getTeams");
      await this.$store.dispatch("getEmployees");

      if(this.$route.query.a){
        this.addEmployee();
      }
    },
    methods: {
      addEmployee() {
        this.employeeModalTitle = 'Add new employee';
        this.addUpdateEmployee = {
            EmployeeId:null,
            User:null,
            FirstName:null,
            LastName:null,
            Email:null,
            TeamId:null,
            Role:null,
        };
        this.employeesAddOrUpdateModal = true;
      },
      addteam() {
        this.teamsAddOrUpdateModalTitle = 'Add new team';
        this.addUpdateTeam = {
            Id:null,
            Title: null,
            Color:null,
        };
        this.teamsAddOrUpdateModal = true;
      },
      updateTrackSettings(employee) {
        this.editTrackSettingsModalTitle = 'Edit track settings "' + (employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName) + '"';
        this.editTrackSettings = {
            EmployeeId:employee.ID,
            EnableCustomTrackSettings: employee.EnableCustomTrackSettings,
            EnableScreenshots: employee.EnableScreenshots,
            BlurScreenshots: employee.BlurScreenshots,
            EnableTimelapseVideos: employee.EnableTimelapseVideos,
            ScreenCaptureIntervalMins: employee.ScreenCaptureIntervalMins
        };
        this.editTrackSettingsModal = true;
      },
      updateTeam(item) {
        this.teamsAddOrUpdateModalTitle = 'Update "' + item.Title + '"';
        this.addUpdateTeam = {
            Id:item.ID,
            Title: item.Title,
            Color:item.Color
        };
        this.teamsAddOrUpdateModal = true;
      },
      updateEmployee(employee) {
        this.employeeModalTitle = 'Edit "' + (employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName) + '"';
        this.addUpdateEmployee = {
            EmployeeId:employee.ID,
            FirstName:(employee.User != null ? employee.User.FirstName : employee.Invitation.FirstName),
            User: employee.User,
            LastName:employee.User != null ? employee.User.LastName : employee.Invitation.LastName,
            Email:employee.User != null ? employee.User.Email : employee.Invitation.Email,
            TeamId:employee.TeamId,
            Role:employee.Role,
        };
        this.employeesAddOrUpdateModal = true;
      },
      deleteEmployee(item) {
        this.deleteEmployeeId = item.ID;
        this.employeeDeleteModalTitle = 'Delete "' + (item.User != null ? item.User.FirstName : item.Invitation.FirstName) + '"'
        this.deleteEmployeeModal = true;
      },
      deleteTeam(item) {
        this.deleteTeamId = item.ID;
        this.deleteTeamModalTitle = 'Delete "' + item.Title + '"'
        this.deleteTeamModal = true;
      },
      async resendInvitation(id) {
        this.loading = 'resend-invitation-' + id;
        await this.$store.dispatch("resendEmployeeInvitation", {
          EmployeeId: id
        })
        this.loading = false;
      },
      tableRowClassName({row, rowIndex}) {
        if (row.isActive == false) {
          return 'red-row';
        }
        return '';
      },
      employeeOperation(id, status) {
        console.log(id, status);
      },
      teamOperation(id, status) {
        console.log(id, status);
      }
    },
    computed: {
      teams() {
        return this.$store.getters.getTeams;
      },
      employees() {
        return this.$store.getters.getEmployees;
      }
    }
}
</script>
