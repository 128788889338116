<template>
    <nav class="navbar-menu" >
        <div class="logo">
            <img src="@/assets/img/logo.svg" alt="" />
        </div>
        
        <div class="menu-container">
            <el-row class="download-btns">
                <el-button round icon="fas fa-life-ring" @click="$underDevelopment">Help</el-button>
                <el-button type="warning" round @click="$underDevelopment">Upgrade</el-button>
            </el-row>

            <el-dropdown class="acc-menu" v-if="account">
                <span class="el-dropdown-link">
                    <div class="user-info">
                        <el-avatar class="acc-avatar" :size="30" :src="account.User.Image"></el-avatar>
                        <div class="user-text">
                            <span>{{ account.User.FirstName + ' ' + account.User.LastName }}</span>
                            <span v-if="account.CurrentOrganization != null">{{ account.CurrentOrganization.OrganizationNo }} - {{ account.CurrentOrganization.Title }}</span>
                            <span v-else>No organization</span>
                        </div>
                    </div>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item v-for="item in account.Employings.Items" :key="item.ID">
                        <template>
                            <div class="organisation-item">
                                <el-avatar class="organisation-avatar" :size="30" shape="square" :src="item.Organization.Image"></el-avatar>
                                <div class="organisation-text">
                                    <span>{{item.Organization.Title}}</span>
                                    <span>{{ item.Organization.OrganizationNo }} - {{ item.Team.Title }}</span>
                                </div>
                            </div>
                        </template>
                    </el-dropdown-item> -->
                    <el-dropdown-item divided @click.native="$underDevelopment">Create an organisation</el-dropdown-item>
                    <el-dropdown-item @click.native="signOut">Sign-out</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        
    </nav>
</template>


<script>
export default {
    name: 'NavBar',
    components: {

    },
    data(){
        return {

        }
    },
    mounted() {
        
    },
    methods: {
        async signOut() {
            await this.$store.dispatch("signOut");
            await this.$router.push({name: "login"});
        }
    },
    computed: {
        account() {
            return this.$store.getters.getAccount;
        },
        
    }
}
</script>

