import axios from "axios";

const store = {
  state: () => ({
    projectListItems:null,
  }),
  getters: {
    getProjectListItems(state) {
      return state.projectListItems;
    }
  },
  mutations: {
    setProjectListItems(state, payload) {
      state.projectListItems = payload;
    }
  },
  actions: {
    async projectListItemsList(store, payload) {
      var res = await axios.post("/projectListItem/list", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }

      store.commit("setProjectListItems", res.data.Data);
      return res.data.Data;
    },
    async projectListItemExport(store, payload) {
      var res = await axios.post("/projectListItem/export", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListItemImport(store, payload, id) {
      debugger;
      alert(payload["Id"]);
      var res = await axios.post("/projectListItem/import?Id=" + id, payload, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });

      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListItemGet(store, payload) {
      var res = await axios.post("/projectListItem/get", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListItemAddOrUpdate(store, payload) {
      var res = await axios.post("/projectListItem/addOrUpdate", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListItemDelete(store, payload) {
      var res = await axios.post("/projectListItem/delete", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
    async projectListItemStartSending(store, payload) {
      var res = await axios.post("/projectListItem/startSending", payload);
      if(res == null || res.data.HasError)
      {
        return null;
      }
      return res.data.Data;
    },
  }
}

export default store;