<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="card p-5 mb-10">
      <div class="organisation-settings-wrapper">
        <el-form :label-position="'top'" label-width="100px" :model="formData">
          <el-form-item label="What is your organization name?" required>
            <el-input v-model="formData.organisation" placeholder="Acme .Inc" required clearable></el-input>
            <ValidationErrorItem validationKey="organisation" />
          </el-form-item>
          <el-form-item class="tracking-buttons">
            <el-button :loading="loading == false" :disabled="formData.organisation == ''" type="primary" icon="fas fa-check" round @click="true">Create organisation</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'CreateOrganizationIndex',
    components: {
      Breadcrumb
    },

    data(){
      return {
        meta: {
          title: 'Create ne organization',
          desc:'You can create a new organization here.'
        },
        formData: {
          organisation: '',
        }
      }
    },
    mounted() {
      this.$setMeta(this.meta);
    }
}
</script>
