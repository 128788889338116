<template>
  <div class="container mt-10">
    <Breadcrumb :title="meta.title" :desc="meta.desc" />

    <div class="activity-filter">
      <div class="filter-item purple" :class="showPage == 0 ? 'isactive' : ''" @click="showPage = 0">
        <i class="fas fa-building"></i>
        <span>Profile</span>
      </div>
      <div class="filter-item purple" :class="showPage == 1 ? 'isactive' : ''" @click="showPage = 1">
        <i class="fas fa-user-secret"></i>
        <span>Track settings</span>
      </div>
      <div class="filter-item purple" :class="showPage == 2 ? 'isactive' : ''" @click="showPage = 2">
        <i class="fas fa-exclamation-triangle"></i>
        <span>Risk user settings</span>
      </div>
      <div class="filter-item purple" :class="showPage == 3 ? 'isactive' : ''" @click="showPage = 3">
        <i class="fas fa-project-diagram"></i>
        <span>API access</span>
      </div>
    </div>

    <template v-if="showPage == 0">
      <div class="card p-5 mb-10">
        <div class="card-header hr">
          <div class="card-title">
            <h3>Organisation profile</h3>
          </div>
        </div>

        <div class="organisation-settings-wrapper">

          <el-form :label-position="'top'" label-width="100px">

            <el-form-item label="Organisation logo" required>
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="organization.Image" :src="organization.Image" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <ValidationErrorItem validationKey="imageUrl" />
            </el-form-item>

            <el-form-item label="Organisation name" required>
              <el-input v-model="organization.Title" placeholder="Acme Inc." required clearable></el-input>
              <ValidationErrorItem validationKey="organisationName" />
            </el-form-item>

            <el-form-item label="Organisation no">
              <el-input v-model="organization.OrganizationNo" placeholder="..."  disabled></el-input>
              <ValidationErrorItem validationKey="organisationNo" />
            </el-form-item>

            <el-form-item label="Organisation type" required>
              <el-radio-group v-model="organization.Invoice.Type">
                <el-radio :label="0">Individual</el-radio>
                <el-radio :label="1">Corporate</el-radio>
              </el-radio-group>
              <ValidationErrorItem validationKey="organisationType" />
            </el-form-item>

            <el-form-item :label="organization.Invoice.Type == 0 ? 'Full name' : 'Commercial title'" required>
              <el-input v-model="organization.Invoice.CommercialTitleOrFullName" placeholder="Acme Inc." required clearable></el-input>
              <ValidationErrorItem validationKey="commercialTitle" />
            </el-form-item>

            <el-form-item :label="organization.Invoice.Type == 0 ? 'Identity number' : 'Tax number'" required>
              <el-input v-model="organization.Invoice.TaxNumberOrIdentity" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="taxNumber" />
            </el-form-item>

            <el-form-item label="Tax office" v-if="organization.Invoice.Type == 1">
              <el-input v-model="organization.Invoice.TaxOffice" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="taxOffice" />
            </el-form-item>

            <el-form-item label="Country" required>
              <el-input v-model="organization.Invoice.Country" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="country" />
            </el-form-item>

            <el-form-item label="Zip code">
              <el-input v-model="organization.Invoice.ZipCode" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="zipCode" />
            </el-form-item>

            <el-form-item label="Invoice address" required>
              <el-input v-model="organization.Invoice.Address" placeholder="..." required clearable></el-input>
              <ValidationErrorItem validationKey="address" />
            </el-form-item>


            <el-form-item class="space-between-buttons">
              <el-button type="danger" icon="fas fa-trash" round @click="deleteOrganizationModal = true">Delete organisation</el-button>

              <el-button :loading="loading == 'updateProfile'" type="primary" icon="fas fa-check" round @click="saveChanges">Save changes</el-button>
            </el-form-item>
          </el-form>

        </div>

      </div>
    </template>

    <template v-if="showPage == 1">
      <el-form :label-position="'top'" label-width="100px" :model="trackSettings">
        <div class="card p-5 mb-10">

          <div class="card-header hr">
            <div class="card-title">
              <h3>Work day/time settings</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">


            <el-row :gutter="24">
              <el-col :span="24">
                <el-form-item label="Working days" required>
                  <el-checkbox-group v-model="trackSettings.WorkingDays">
                    <el-checkbox :label="1" name="type">Monday</el-checkbox>
                    <el-checkbox :label="2" name="type">Tuesday</el-checkbox>
                    <el-checkbox :label="3" name="type">Wednesday</el-checkbox>
                    <el-checkbox :label="4" name="type">Thursday</el-checkbox>
                    <el-checkbox :label="5" name="type">Friday</el-checkbox>
                    <el-checkbox :label="6" name="type">Saturday</el-checkbox>
                    <el-checkbox :label="7" name="type">Sunday</el-checkbox>
                  </el-checkbox-group>
                  <ValidationErrorItem validationKey="days" />
                </el-form-item>
              </el-col>
            </el-row>

            

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Expected work hours" required>
                  <el-select v-model="trackSettings.ExpectedWorkHours" placeholder="Select...">
                    <el-option
                      v-for="item in workingHours"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="ExpectedWorkHours" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Minimum productive work hours" required>
                  <el-select v-model="trackSettings.MinimumProductiveMins" placeholder="Select...">
                    <el-option
                      v-for="item in workingHours"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="minProductive" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Expected clock-in" required>
                  <el-time-select v-model="trackSettings.Clockin" :picker-options="timePickerOptions" placeholder="Select..."></el-time-select>
                  <ValidationErrorItem validationKey="Clockin" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Expected clock-out" required>
                   <el-time-select v-model="trackSettings.Clockout" :picker-options="timePickerOptions" placeholder="Select..."></el-time-select>
                  <ValidationErrorItem validationKey="Clockout" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="Idle time settings" required>
                  <el-select v-model="trackSettings.IdleTimeMins" placeholder="Select...">
                    <el-option
                      v-for="item in idleTimes"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="IdleTimeMins" />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Organisation timezone" required>
                  <el-select v-model="trackSettings.Timezone" placeholder="Select...">
                    <el-option
                      v-for="item in timezones"
                      :key="item.Id"
                      :label="item.Title + ' (' + item.BaseUtcOffset + ')'"
                      :value="item.Id">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="timeZone" />
                </el-form-item>
              </el-col>
            </el-row>

          </div>

          <div class="card-header hr">
            <div class="card-title">
              <h3>Screenshot settings</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">

            <el-row :gutter="24" class="mb-3 mt-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.EnableScreenshots" active-text="Enable screen capture" />
                <ValidationErrorItem validationKey="EnableScreenshots" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.BlurScreenshots" active-text="Blur captured screenshots" />
                <ValidationErrorItem validationKey="BlurScreenshots" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.EnableTimelapseVideos" active-text="Enable timelapse videos" />
                <ValidationErrorItem validationKey="EnableTimelapseVideos" />
              </el-col>
            </el-row>

            <el-row :gutter="24" v-if="trackSettings.EnableScreenshots">
              <el-col :span="24">
                <el-form-item label="Screen capture interval" required>
                  <el-select v-model="trackSettings.ScreenCaptureIntervalMins" placeholder="Select...">
                    <el-option
                      v-for="item in idleTimes"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key">
                    </el-option>
                  </el-select>
                  <ValidationErrorItem validationKey="ScreenCaptureIntervalMins" />
                </el-form-item>
              </el-col>
            </el-row>

          </div>

          <div class="card-header hr">
            <div class="card-title">
              <h3>Permissions</h3>
            </div>
          </div>
          <div class="track-settings-wrapper">


            <el-row :gutter="24" class="mb-3 mt-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <p>Screenshots</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToDeleteTheirMembersScreenCapture" active-text="Allow managers to delete their team member's screenshot." />
                <ValidationErrorItem validationKey="AllowManagersToDeleteTheirMembersScreenCapture" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirScreenCapture" active-text="Allow the employee to see their screen capture." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirScreenCapture" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableScreenshots">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToDeleteTheirScreenCapture" active-text="Allow the employee to delete their screen capture." />
                <ValidationErrorItem validationKey="AllowEmployeeToDeleteTheirScreenCapture" />
              </el-col>
            </el-row>


            <el-row :gutter="24" class="mb-3 mt-3" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <p>Timelapse video</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowManagersToDeleteTheirMembersTimelapseVideo" active-text="Allow managers to delete their team member's timelapse video." />
                <ValidationErrorItem validationKey="AllowManagersToDeleteTheirMembersTimelapseVideo" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3" v-if="trackSettings.EnableTimelapseVideos">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirTimelapseVideo" active-text="Allow the employee to see their time-lapse video." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirTimelapseVideo" />
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3 mt-3">
              <el-col :span="12">
                <p>Timesheet</p>
              </el-col>
            </el-row>

            <el-row :gutter="24" class="mb-3">
              <el-col :span="12">
                <el-switch v-model="trackSettings.AllowEmployeeToSeeTheirTimesheet" active-text="Allow the employee to see their timesheet." />
                <ValidationErrorItem validationKey="AllowEmployeeToSeeTheirTimesheet" />
              </el-col>
            </el-row>

            <el-form-item class="tracking-buttons">
              <el-button :loading="loading == 'updateTrackSettings'" type="primary" icon="fas fa-check" round @click="updateTrackSettings">Save changes</el-button>
            </el-form-item>

          </div>

        </div>
      </el-form>
    </template>

    <template v-if="showPage == 2">
      <div class="card p-5 bg-purple">
        <h3 class="mt-1 mb-1">Coming soon</h3>
        <p class="mt-1 mb-1">This feature is currently on the development list.</p>
        <p class="mt-1 mb-1">Please let us know if you intend to use this feature by clicking <a href="javascript:;">here</a>. We will be sending you an e-mail when the feature is complete.</p>
      </div>
    </template>

    <template v-if="showPage == 3">
      <div class="card p-5 bg-purple">
        <h3 class="mt-1 mb-1">Coming soon</h3>
        <p class="mt-1 mb-1">This feature is currently on the development list.</p>
        <p class="mt-1 mb-1">Please let us know if you intend to use this feature by clicking <a href="javascript:;">here</a>. We will be sending you an e-mail when the feature is complete.</p>
      </div>
    </template>

    <el-dialog class="medium" title="Delete organization" :visible.sync="deleteOrganizationModal" center modal-append-to-body destroy-on-close>
      <DeleteOrganizationModal @closeDialog="deleteOrganizationModal = false" v-if="deleteOrganizationModal"/>
    </el-dialog>


  </div>
</template>

<script>

import DeleteOrganizationModal from './DeleteOrganizationModal.vue';

import Breadcrumb from '../partials/Breadcrumb';

export default {
    name: 'OrganizationSettingsIndex',
    components: {
      Breadcrumb,
      DeleteOrganizationModal
    },
    data(){
      return {
        meta: {
          title: 'Organisation settings',
          desc:'You can change organisation profile or track settings here.'
        },
        deleteOrganizationModal:false,
        timePickerOptions: {
          start: '00:15',
          step: '00:15',
          end: '23:45'
        },
        showPage: 0,
        loading: null
      }
    },
    async mounted() {
      this.$setMeta(this.meta);
      await this.$store.dispatch("getTimezones");
    },
    methods: {
      
      async saveChanges() {
        this.loading = 'updateProfile';
        await this.$store.dispatch("updateOrganizationProfile", this.organization);
        this.loading = null;
      },
      
      async updateTrackSettings() {
        this.loading = 'updateTrackSettings';
        await this.$store.dispatch("updateTrackSettings", this.organization.TrackSettings);
        this.loading = null;
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('Avatar picture must be JPG format!');
        }
        if (!isLt2M) {
          this.$message.error('Avatar picture size can not exceed 2MB!');
        }
        return isJPG && isLt2M;
      }
    },
    computed: {
      timezones() {
            return this.$store.getters.getTimezones;
      },
      account() {
            return this.$store.getters.getAccount;
      },
      organization() {
            return this.account.CurrentOrganization;
      },
      trackSettings() {
            return this.organization.TrackSettings;
      },
      workingHours() {
        var result = [];

        for (let i = 15; i <= 960; i += 15) {
          result.push({
            Key:i,
            Value: this.$parseTime(i)
          });     
        }

        return result;
      },
      idleTimes() {
        var result = [];

        for (let i = 1; i <= 60; i++) {
          result.push({
            Key:i,
            Value: "Every " + i + " minutes"
          });     
        }

        return result;
      },
    },
    watch: {
      'trackSettings.EnableScreenshots' () {
        if(this.trackSettings.EnableScreenshots == false)
        {
          this.trackSettings.BlurScreenshots = false;
          this.trackSettings.EnableTimelapseVideos = false;
        }
      }
    }
}
</script>
