<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  async created(){
    await Promise.all([
      await this.$store.dispatch("getEnums"),
      await this.$store.dispatch("getAccount")
    ]);
  },
}
</script>

<style lang="less">
  @import url("./assets/css/style.less");
</style>
